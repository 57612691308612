@import '../../../../../components/_globals/variables';
@import '../../../../../pages/GuestPages/Login/Login.scss';
.custom-list-save-button {
    @extend .guest__button;
    padding: 10px;
    font-size: 15px;
    margin: 10px;
    width: 170px;
    height: 45px;
    border-radius: 33px;
    float: right;

}
.custom-list-item {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}
.input-definition {
    width: 600px;
}
.custom-list-label {
    width: 200px;
}
.custom-list-title {
    font-size: 25px;
}
.custom-list-test-label {
    width: 100px;
}
.input-case-number {
    width: 80px;
}
.custom-list-test-button {
    @extend .guest__button;
    // padding: 10px;
    font-size: 15px;
    margin: 10px;
    width: 100px;
    height: 35px;
    border-radius: 33px;
    float: right;

}
.input-case-test-result {
    color: black !important;
    background-color: white !important;
    cursor: auto !important;
}