.begin_case__wrapper {
  background: white;
  padding: 30px 0;
  // width: 70%;
  // max-width: 640px;
  img {
    max-width: 100%;
  }
  @include small {
    max-width: 100%;
    width: 100%;
  }
}
.begin_case__title {
  font-size: 18px;
  padding-bottom: 10px;
}
.begin_case__markdown_description {
  background: rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 20px;
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
.begin_case__description {
  font-size: 18px;
  font-style: italic;
  padding-bottom: 30px;
}

.begin_case__buttons {
  cursor: pointer;
  padding: 30px;
  width: 100%;
}
.begin_case__button_cont {
  width: 100%;
}
.begin_case__button {
  display: block;
  width: 100%;
  border-radius: 30px;
  background: #9100ff;
  padding: 15px;
  display: flex;
  justify-content: center;
  color: white;
  margin-bottom: 15px;
  &--back {
    background: #844b9e;
  }
}

.begin_case__inside {
  justify-content: center;
}
