.table-wrapper{
    .ant-table-wrapper{
        padding: 0;
        background: white;
    }
    .ant-table-tbody{
        background: white;
    }
    .ant-table-thead{
        .ant-table-cell{
            background: white;
            font-size: 16px;
            line-height: 19px;
            border-bottom: 1px solid black
        }
    }
}
.switch {
    .ant-switch-checked{
        background-color: #9100ff !important;
        color: rgba(0, 0, 0, 0) !important;
    }
    .ant-switch {
        background-color: rgba(0, 0, 0, 0) !important;
        border: 1px solid black !important;
        
    }
    .ant-switch.ant-switch-checked {
        background-color: rgba(0, 0, 0, 0) !important;
        border: 1px solid black !important;
        .ant-switch-handle::before{
            background-color: #9100ff;
        }
        
    }
    .ant-switch-handle{
        top: 2px;
        width: 16px;
        height: 16px;
    }
    .ant-switch-handle::before{
        background-color: #D8D6D6;;
    }
    
}
.user_list_title {
    display: flex;
    justify-content: space-between;
}