.properties {
  .ant-collapse-header {
    height: 50px;
    background: #6956AB;
    color: white !important;
    font-size: 18px;
    text-align: left;
  }
  .ant-collapse-content-box {
    background: #F6F4FD;
    padding: 25px;
  }
  .ant-collapse-item-active {
    border-bottom: 0 !important;
  }
  .ant-collapse-arrow {
    font-size: 30px !important;
  }
}
.input-container {
  .label {
    color: #000000;
    font-size: 14px;
    font-weight: lighter;
    text-align: left;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .textarea {
    height: 60px !important;
  }
  .ant-input {
    height: 40px;
  }
}
.icon-uploader > .ant-upload {
  width: 100px;
  height: 100px;
}
