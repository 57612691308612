@import '../../pages/GuestPages/Login/Login.scss';

.desktop_header__wrapper {
  padding: 20px 0px;
  height: 74px;
  width: 100%;
  background: white;
}
.desktop_header__logo {
  img {
    height: 50px;
    padding-left: 20px;
  }
}
.desktop_header {
  display: flex;
  min-height: 12vh;
  // position: fixed;
  background: white;
  width: 97%;
  z-index: 100;
}
.desktop_header__greeting {
  align-items: center;
  display: flex;
  flex: 3;
}
.desktop_header__greeting_text {
  padding: 10px;
}
.desktop_header__greeting_brand {
  img {
    max-width: 80px;
  }
}

.desktop_header__search_bar {
  align-items: center;
  flex: 7;
  display: flex;
}
.desktop_header__search_bar_wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 45px;
}
.desktop_header__search_bar_input {
  background: none;
  border: 0;
  border-bottom: 1px solid #cccccc;
  display: block;
  margin: 0 5px;
  padding: 0 30px;
  position: relative;
  width: 70%;
  .ant-select-selector {
    border: none !important;
  }
  .ant-select-selector {
    box-shadow: none !important;
  }
}
.desktop_header__search_bar_button {
  @extend .guest__button;
  background: #9100ff;
  font-size: 18px;
  font-weight: 400;
  margin: 0 5px;
  width: 20%;
  line-height: normal;
  border-radius: 34px;
}
.desktop_header__notifications {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 3;
}
.desktop_header__notifications_bell,
.desktop_header__notifications_user {
  width: 50px;
  img {
    display: block;
    max-width: 24px;
  }
}
.default_img_company {
  max-width: 35px !important;
}
.h_top_icon {
  position: absolute;
  top: 10px;
  left: 5px;
}
:root {
  --avatar-size: 55px;
  /* change this value anything, e.g., 100px, 10rem, etc. */
}

.circle {
  border-radius: 50%;
  height: var(--avatar-size);
  text-align: center;
  width: var(--avatar-size);
  border: 1px solid #c1bbbb
}

.initials {
  font-size: calc(var(--avatar-size) / 3); /* 50% of parent */
  position: relative;
  bottom: calc(var(--avatar-size) / 9); /* 25% of parent */
}

.plan {
  border: 1px solid #9100ff;
  font-size: 18px;
  font-weight: 400;
  color: #7A4F97;
  margin: 0 5px;
  width: 180px;
  border-radius: 34px;
  height: 45px;
  text-align: center;
  span {
    line-height: normal;
    position: relative;
    top: -10px;
    font-weight: bold;
    
  }
}
.avatar{
  border: 1px solid #c1bbbb;
  color: black;
  background-color: white;
}
.profile{
  // float: right;
  right: -16px;
  bottom: 16px;
  cursor: pointer;
  text-align: right;
  svg{
    font-size: 23px;
    padding-left: 4px;
    vertical-align: middle;
  }
}
.company_logo{
  bottom: 16px;
}
