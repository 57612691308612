@import '../../../components/_globals/layouts.scss';

.myacc__wrapper {
  width: 100%;
  padding-left: 75px;
}

.myacc {
  width: 100%;

  @include small {
    padding: 15px;
  }
}

.myacc__separator {
  display: flex;

  @include small {
    flex-direction: column;
  }
}

.myacc__title {
  display: flex;

  h1 {
    font-weight: 300;
    letter-spacing: 0.5px;
    padding-bottom: 30px;
  }
}

.myacc__billing_info {
  width: 55%;

  @include small {
    width: 100%;
  }
}

.myacc__subtitle {
  align-items: center;
  display: flex;

  h2 {
    color: #1a1a1a;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 18px;
    padding-left: 10px;
  }

  i {
    font-size: 26px;
  }
}

.myacc__consumption {
  width: 80%;
  padding-bottom: 50px;
}

.myacc__plan_card {
  width: 45%;

  @include small {
    margin: 0 auto;
    width: 100%;
  }
}

.billing_info__form {
  padding: 30px 0;
  width: 70%;

  @include small {
    width: 100%;
  }
}

.billing_info_id {
  width: 70%;
}

.billing_info__element {
  padding: 5px 0;
}

.billing_info__btn {
  @extend .guest__button;
  font-size: 14px;
  margin: 20px 0;
  padding: 10px 30px;
}

.myacc__plan_data_wrapper {
  display: flex;
  padding: 20px 20px 50px 20px;
  width: 100%;

  @include small {
    margin: 10px auto;
    max-width: 300px;
  }
}

.myacc__plan_data {
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
  min-width: 200px;
  text-align: center;
  max-width: 250px;
  width: 70%;

  @include small {
    width: 100%;
  }
}

.myacc__plan_type {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.myacc__plan_payment {
  padding: 5px 0;
}

.myacc__plan_upgrade {
  a {
    @extend .billing_info__btn;
    margin: 0;
    border-radius: 0 0 10px 10px;
    display: block;
    width: 100%;
  }
}

.myacc__pan_separator {
  padding: 25px 0;
}

.myac__card_empt_btn {
  padding: 0 20px;

  button {
    @extend .billing_info__btn;
    padding: 10px 40px;

    @include large {
      padding: 10px 15px;
    }

    @include small {
      padding: 12px 60px;
    }
  }
}

.myacc__card--nocard {
  padding: 20px;
}

.myacc__card__empt_text {}

.myacc__card_empt_separator {
  align-items: center;
  display: flex;

  @include small {
    justify-content: center;
    flex-direction: column-reverse;
  }
}

.myacc__card_empt_img {
  max-width: 200px;

  @include large {
    max-width: 120px;
  }

  @include small {
    max-width: 200px;
  }

  img {
    width: 100%;
  }
}

.myacc_card {}

.cc_number__img {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -30px;

  img {
    width: 35px;
    height: 25px;
  }
}

.myacc__plan_upgrade--disabled {
  background: #333333 !important;
  cursor: not-allowed !important;
}