@import '../../GuestPages/Login/Login.scss';
.workflow__wrapper {
  // padding-top: 48px;
  // overflow: auto;
  overflow: hidden;
}
.workflow__tit {
  background: #9276f4;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  p {
    font-weight: lighter;
    color: white;
    font-size: 18px;
    padding: 5px 5px;
    img {
      padding-right: 5px;
      height: 23px;
    }
  }
  b{
    font-weight: bolder;
  }
}
.workflow__back_arrow {
  color: white;
  font-size: 32px;
  display: inline-block;
  svg {
    cursor: pointer;
    margin: 0 24px 0 15px;
  }
  
}
.workflow__tit_name {
  display: inline-block;
  p {
    display: inline-block;
    vertical-align: super;
  }
  // vertical-align: super;
}
.workflow__tit_role {
  p{
    padding-top: 10px;
    padding-right: 10px;
  }
}
.workflow__actions {
  align-items: center;
  background: #F8F8F8;
  bottom: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  // left: 50%;
  // transform: translateX(-50%);
  // width: 1490px;
  width: 100%;
}

.workflow__actions {
  button {
    @extend .guest__button;
    padding: 10px;
    font-size: 15px;
    margin: 10px;
    width: 180px;
  }
}
.workflow_back_down_button {
  font-size: 18px;
  display: block;
  width: 100%;
  border-radius: 30px;
  // background: #9100ff;
  padding: 15px;
  display: flex;
  justify-content: center;
  color:  #9100ff;
  align-items: center;
  font-weight: bold;
  &:hover {
    color: #9100ff;
  }
  svg {
    font-size: 37px;
    margin-right: 15px;
  }
  &--back {
    background: #844b9e;
  }
}
.role-swimlane-container {
  padding-bottom: 60px;
  height: calc(100vh - 117px);
  overflow: auto;
}
.side-properties-container {
  padding-bottom: 60px;
  height: calc(100vh - 117px);
  overflow: auto;
  background: #F6F4FD;
  min-height: 70vh;
  
}
.form_builder__sidebar {
  background: #F6F4FD;
  min-height: 70vh;
  flex-flow: column;
  height: calc(100vh - 117px);
  overflow: auto;
  min-width: 325px;
}
.version-select{
  display: inline-block;
  padding-top: 10px;

  .ant-select-selector{
    width: 200px !important;
    background-color: transparent !important;
    border:none !important;
    color: white;
  }
  .ant-select-arrow{
    color: white !important;
    top: 42%;
    font-size: 16px;
  }
}
.new-draft-button {
  padding-top: 9px;
  button{
    min-height: 32px;
    width: 200px;
    border-radius: 15px;
    background-color: #4A3693;
    color: white;
    border: none;
    font-size: 12px;
  }
}
.publish-draft-button {
  padding-top: 9px;
  button{
    min-height: 32px;
    width: 65px;
    border-radius: 15px;
    background-color: #4A3693;
    color: white;
    border: none;
    font-size: 19px;
    margin-left: 10px;
  }
}
.version-actions {
  display: flex;
  padding-right: 20px;
}
.draft-avatar{
  padding-top: 9px;
}
.draft-modal{
  .ant-modal-content{
    height: 700px;
  }
  .ant-modal-body{
    height: 645px;
  }
}