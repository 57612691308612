.form_list_widget {
  background-color: rgb(255, 255, 255);
  border: 1px solid #d0cfcf;
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  margin: 14px 14px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.form_list_widget__icon {
  flex-grow: 1;
  width: 22px;
  height: 18px;
  margin-right: 5px;
}

.form_list_widget--faded {
  background: lighten(#e2dcfb, 10);
}

.form_list_title {
  font-size: 16px;
  float: left;
  width: 63%;
  &__actions {
    font-size: 14px;
    float: right;
    cursor: pointer;
  }
}

.form_widget__title {
  display: block;
  flex-grow: 100;
}

.form_widget__actions {
  float: right;
  flex-grow: 1;
  // align-self:flex-end;
}

.form_list_header {
  height: 45px;
  padding-top: 7px;
}

.conflict-modal{
  .ant-modal-footer {
    border-radius: 10px;
    border-top: 0;
    text-align: center;
    button {
      // width: 117px;
      border-radius: 25.5px;
      background-color:#9100ff;
      color: white;
    }
  }
}
