@import '../../../../components/_globals/variables';

.featured_providers {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include small {
    width: 100%;
  }
}

.featured_providers__no_list {
  background: rgba(0, 0, 0, 0.05);
  padding: 3px 0;
  font-style: italic;
  width: 100%;
}
.featured_providers__element {
  border: 1px solid #979797;
  align-items: center;
  background: white;
  border-radius: 15px;
  color: #666369;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  height: 120px;
  justify-content: center;
  // padding: 0 10px;
  transition: 0.2s ease-in;
  max-width: 150px;
  &:focus,
  &:hover {
    background: white;
    color: white;
  }
}
.featured_providers__image {
  text-align: center;
  padding: 5px 0;
  img {
    max-width: 100%;
  }
}
.featured_providers__description {
  font-size: 12px;
  text-align: center;
  height: 36px;
}
.featured_providers__header {
  font-size: 14px;
  align-self: flex-end;
  font-weight: bolder;
}

.featured_providers--desktop {
  padding: 0 !important;
  margin: 15px 0;
  width: 100%;
}

.featured_providers__element--desktop {
  width: 93% !important;
  flex: none;
}
