h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
body,
aside,
section,
article,
blockquote,
ul,
li {
  margin: 0;
  padding: 0;
}

body {
  color: #3f3f3f;
  font-size: 16px;
  font-family: 'Ubuntu', Arial, serif;
}
a:focus {
  color: #eeeeee;
}
