@import '../../../../../components/_globals/variables';
@import '../../../../../pages/GuestPages/Login/Login.scss';
.new-section-button {
    @extend .guest__button;
    padding: 10px;
    font-size: 15px;
    margin: 10px;
    width: 170px;
    height: 45px;
    border-radius: 33px;
    float: right;

}
.analytics-list-title {
    display: flex;
    justify-content: space-between;
    color: #444444;
    font-size: 26px;
    font-weight: 400;
    align-items: center;
}
.modal__new_form {
}
.modal__new_form_title {
  font-size: 13px;
  padding: 10px 0;
}
.modall__new_form_message {
  color: tomato;
  font-style: italic;
  padding-top: 10px;
}
.form_list__back_arrow {
  color: #9100ff;
  font-size: 28px;
  display: inline-block;
  svg {
    cursor: pointer;
    margin-right: 55px;
  }
  
}
.analytics-list-panel {
    background-color: #F3F5F9;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.add-analytic-icon{
    width: auto !important;
    svg {
      font-size: 72px;
      color: #7D8BD4;
    }
    span {
      padding-top: 4px;
    }
  }

.report-box {
  background-color: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 22px;
  box-shadow: 0 9px 11px 0 rgba(203, 203, 203, 0.5);
  width: 240px;
  height: 200px;
  margin: 16px;
}
.report-box-header {
    height: 21px;
    border-bottom: 1px solid #EFEFEF;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
}
.report-box-content {
    padding-top: 20px;
    height: 155px;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
    p {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px 9px;
        text-align: left;
    }
}
.ini-end-text {
    padding-top: 20px;
}
.process-title-text {
    padding-top: 20px;
    font-size: 20px;
}
.process-logo {
    display: inline-block;
    padding-top: 12px;
    width: 60px;
    height: 60px;
    img {
        max-height: 60px;
        // filter: brightness(300%);
      }
}
.report-box-footer {
    height: 44px;
    background-color: #9100ff;
    border-bottom-right-radius: 22px;
    border-bottom-left-radius: 22px;
    padding: 9px 56px;
    display: flex;
    justify-content: space-between;
    .ant-switch {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid white;
    }
    .ant-switch-handle{
        top: 2px;
        width: 16px;
        height: 16px;
    }
    .ant-switch-handle::before{
        border: 1px solid white;
        background-color: rgba(0, 0, 0, 0);
    }
    // justify-content: space-between;
    &__inactive {
        @extend .report-box-footer;
        background-color: #CACACA;
    }
    &__private {
        @extend .report-box-footer;
        background-color: #7D8BD4;
    }
}
.report-box-container {
    background-color: #F3F5F9;
    min-width: 280px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.delete-form {
    cursor: pointer;
    color: white;
    padding-right: 18px;
}
.edit-form {
    cursor: pointer;
    padding-left: 18px;
    a{
        color: white;
    }
}
.edit-intro {
    cursor: pointer;
    a{
        color: white;
    }
}
.new-report {
  text-align: center !important;
}
.report-logo {
  margin-top: 27px;
}