@import '../../../../../components/_globals/variables';
@import '../../../../../pages/GuestPages/Login/Login.scss';
.draft-table-wrapper{
  .ant-table-wrapper{
      padding: 0;
      background: white;
  }
  .ant-table-tbody{
      background: white;
  }
  .ant-table-thead{
      .ant-table-cell{
          background: white;
          font-size: 16px;
          line-height: 19px;
          border-bottom: 1px solid black
      }
  }
}
.new-draft-case-button {
  @extend .guest__button;
  padding: 10px;
  font-size: 15px;
  margin: 10px;
  width: 170px;
  height: 45px;
  border-radius: 33px;
  float: right;
  // background: #9100ff;
  // font-size: 16px;
  // font-weight: 400;
  // color: white;
  // margin: 0 5px;
  // width: 248px;
  // border-radius: 34px;
  // height: 66px;
  // text-align: center;
  // span {
  //   line-height: normal;
  //   position: relative;
  //   top: 15%;
  //   font-weight: bold;
  // }
  // img {
  //   padding-top: 16px;
  //   padding-right: 10px;
  // }
}
