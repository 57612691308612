@import '../_globals/variables';

.sidebar__shadow {
  background: rgba(black, 0.6);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: $transition;
  visibility: hidden;
  width: 100%;
  z-index: 9;
}

.sidebar__wrappper {
  background: $main01;
  box-shadow: 2px, 2px, 5px rgba(white, 0.5);
  left: -100%;
  max-width: 600px;
  min-height: 100vh;
  position: fixed;
  top: 0;
  transition: $transition;
  width: 80%;
  z-index: 10;
}
.sidebar {
  color: white;
  position: relative;
  top: $xs_header_min_height - 20px;
}
.sidebar__close_icon {
  font-size: 22px;
  position: relative;
  top: -35px;
  padding: 0 10px;
  max-width: 60px;
}
.sidebar__list {
}
.sidebar__element {
  &:last-child {
    border-bottom: 1px solid rgba(white, 0.5);
  }
}
.sidebar__link {
  border-top: 1px solid rgba(white, 0.5);
  color: white;
  display: block;
  padding: 10px;
  width: 100%;
}
.sidebar__link--selected {
  background: rgba(255, 255, 255, 0.2);
}

// sidebar interactions classes
.sidebar__shadow_show {
  opacity: 1;
  visibility: visible;
}
.sidebar__wrapper_show {
  left: 0;
}
