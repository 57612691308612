@import '../../../components/_globals/variables';
@import '../Login/Login.scss';
.invitation_wrapper{
  .ant-row {
    flex-flow: nowrap;
  }
  margin: 0 auto;
  background-color: white;
  // width: 1352px;
  // height: 889px;
  width: 1095px;
  height: 720px;
  .image-wrapper{
    img {
      flex: 1;
      height: 720px;
      width: auto;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
  -webkit-box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.75);
  border-radius: 10px;


}
.country-button{
  height: 90px !important;
  width: 98px;
  padding: 0 6px;
  line-height: 30px !important;
  text-align: center;
  p {
    font-size: 13px;
    text-align: center;
    line-height: 16px;
    
  }
  img {
    border-radius: 0;
  }
  
}
.ant-radio-button-wrapper{
  border: none;
  border-left: none;
}
.ant-radio-button-wrapper:not(:first-child)::before{
  display: none;
}
.invitation_back{
  background-color: #FAF6FD;
  height: 100vh;
  // vertical center align
  display: -ms-flexbox!important;
  display: flex!important;
  -ms-flex-align: center!important;
  align-items: center!important;
}
.invitation_form_wrapper {
  padding-left: 25px;
  padding-top: 68px;
  padding-right: 25px;
  
  .invitation_form_title {
    font-family: Ubuntu;
    font-size: 35px;
    line-height: 48px;
    text-align: left;
    padding-bottom: 25px;
    max-width: 100%;
  }
  .invitation_form_subtitle {
    font-family: Ubuntu;
    font-size: 15px;
    line-height: 19px;
    text-align: left;
    padding-bottom: 75px;
    max-width: 100%;
  }
  .invitation_form_inline {
    display: flex;
    justify-content: space-between;
    .invitation_form_field {
      width: 250px;
    }
  }
  button {
    background-image: linear-gradient(270deg, #B480FA 0%, #7D49F3 100%);
    margin-top: 35px;
    width: 100%;
    height: 55px;
    border: 0;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: bolder;
    letter-spacing: 0.5px;
    position: relative;
    padding: 5px 0;
    transition: 0.15s ease-in;
    span {
      display: inline-block;
    }
    &:hover {
      background-image: linear-gradient(270deg, #B480FA 0%, #7D49F3 100%);
    }
  }
}
.invitation_modal_success {
  .ant-modal-confirm-btns{
    float: none;
    text-align: center;
  }
  button {
    @extend .guest__button;
    // padding: 10px;
    font-size: 17px;
    margin: 10px;
    width: 216px;
    height: 56px;
    border-radius: 33px;
  }
}
