@import '../../../components/_globals/variables';
@import '../../../pages/GuestPages/Login/Login.scss';

.wrapper{
  margin: 0px 210px;
  width: 861px;
}
.invite_button {
  @extend .guest__button;
  padding: 10px;
  font-size: 15px;
  margin: 10px;
  width: 248px;
  height: 66px;
  border-radius: 33px;
  float: right;
  // background: #9100ff;
  // font-size: 16px;
  // font-weight: 400;
  // color: white;
  // margin: 0 5px;
  // width: 248px;
  // border-radius: 34px;
  // height: 66px;
  // text-align: center;
  // span {
  //   line-height: normal;
  //   position: relative;
  //   top: 15%;
  //   font-weight: bold;
  // }
  // img {
  //   padding-top: 16px;
  //   padding-right: 10px;
  // }
}
.table_title {
  font-size: 30px;
  line-height: 36px;
}
.invitation_modal {
  .ant-modal-body{
    height: 385px !important;
  }
  .ant-modal-header{
    border-bottom: 0;
  }
  .invitation_modal_subtitle{
    align-content: center;
    text-align: center;
    font-size: 17px;
    padding-bottom: 25px;
  }
  .invitation_modal_field{
    input {
      height: 48px;
    }
  }
  .invitation_modal_footer{
    text-align: center;
    button {
      @extend .guest__button;
      // padding: 10px;
      font-size: 17px;
      margin: 10px;
      width: 216px;
      height: 56px;
      border-radius: 33px;
    }
  }
}
.invitation_modal_ok {
  .ant-modal-confirm-btns{
    float: none;
    text-align: center;
  }
  button {
    @extend .guest__button;
    // padding: 10px;
    font-size: 17px;
    margin: 10px;
    width: 216px;
    height: 56px;
    border-radius: 33px;
  }
  button:focus {
    background: #9100ff;
  }
}
.user_modal {
  .ant-modal-body{
    height: 500px !important;
  }
  .ant-modal-header{
    border-bottom: 0;
  }
  .user_modal_subtitle{
    align-content: center;
    text-align: center;
    font-size: 17px;
    padding-bottom: 25px;
  }
  .user_modal_field{
    input {
      height: 48px;
    }
  }
  .user_modal_footer{
    text-align: center;
    button {
      @extend .guest__button;
      // padding: 10px;
      font-size: 17px;
      margin: 10px;
      width: 216px;
      height: 56px;
      border-radius: 33px;
    }
  }
}
.user_modal_ok {
  .ant-modal-confirm-btns{
    float: none;
    text-align: center;
  }
  button {
    @extend .guest__button;
    // padding: 10px;
    font-size: 17px;
    margin: 10px;
    width: 216px;
    height: 56px;
    border-radius: 33px;
  }
  button:focus {
    background: #9100ff;
  }
}
.user-info-form{
  max-width: 540px;
  .ant-form-item-label {
      padding: 0;
  }
  label {
      color: #070707;
      font-family: Ubuntu;
      font-size: 17px;
      font-weight: 500;
  }
  input {
      border: none;
      border-bottom: 1px solid #d9d9d9;
      color: #060606;
      font-family: Ubuntu;
      font-size: 15px;
      // line-height: 18px;
  }
  input:focus {
      border-color: #9100ff;
      box-shadow: none;
      -webkit-box-shadow: 0px 1px 0px 0px #9100ff;
      box-shadow: 0px 1px 0px 0px #9100ff;
      
  }
  input:hover {
      border-color: #9100ff;
  }
  .ant-select-selector {
      border: none !important;
      border-bottom: 1px solid #d9d9d9 !important;
      color: #060606;
      font-family: Ubuntu;
      font-size: 15px;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      input:focus {
          border: none !important;
          box-shadow: none !important;
      }
  }
  .ant-select-selector:focus {
      border-color: #9100ff;
      box-shadow: none;
      -webkit-box-shadow: 0px 1px 0px 0px #9100ff;
      box-shadow: 0px 1px 0px 0px #9100ff;
  }
  .ant-select-focused {
      border-color: #9100ff;
      box-shadow: none;
      -webkit-box-shadow: 0px 1px 0px 0px #9100ff;
      box-shadow: 0px 1px 0px 0px #9100ff;

  }
}
.user_modal_fields{
  label{
    font-weight: bold;
  }
  input {
    border-bottom: 1px solid #7B5099 !important;
    color: black !important;
  }
}
.add_profile{
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    background: #9100ff;
    border-color: #9100ff;
  }
  button:hover {
    background: #9100ff;
    border-color: #9100ff;
  }
}
.user_info_loading {
  font-size: 75px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
