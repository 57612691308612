@import '../../../components/_globals/variables';
@import '../../../pages/GuestPages/Login/Login.scss';

.process_list {
  padding: 20px 10px;
  width: 100%;
}
.process_list__title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;
}
.process_list__left {
  align-items: center;
  display: flex;
}
.process_list__icon {
  padding-right: 10px;
}
.process_list__title_text {
  h1 {
    color: #444444;
    font-size: 26px;
    font-weight: 400;
  }
}
.process_list__right {
}
.process_list__button {
  @extend .guest__button;
  background: #9100ff;
  border-radius: 50px;
  display: flex;
  font-size: 16px;
  padding: 10px 30px;
  &:hover {
    background: darken(#9100ff, 10);
  }
}

.icon_separator {
  display: flex;
  font-size: 22px;
  padding-right: 5px;
}

.process_list__main {
  width: 100%;
}
.process_list__table_wrapper {
  padding: 40px 0;
  width: 100%;
}
.process_list__table {
  width: 100%;
  thead {
    td {
      font-weight: bold;
      padding: 10px 0;
    }
  }
  tbody {
    background: #f9f9f9;
    border: 1px solid #f1f1f1;
    margin: 10px 0;
    @include small {
      background: white;
    }
    td {
      border-bottom: 1px solid #cccccc;
      &:first-child {
        padding: 20px 3px 20px 20px;
      }
      &:last-child {
        padding: 20px 20px 20px 3px;
      }
    }
  }
}

.process_list__actions {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  width: 100%;

  .ant-switch-checked {
    background: $main01;
  }
}

.table_element--icon {
  padding: 3px;
  img {
    height: 29px;
    width: 29px;
  }
}

.no_border_bottom {
  border: 1px solid rgba(0, 0, 0, 0) !important;
}
.h_process_name {
  font-weight: bold;
  padding: 5px;
}
.h_blocked {
  cursor: not-allowed !important;
}

.report_listx__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.modal__new_form {
}
.modal__new_form_title {
  font-size: 13px;
  padding: 10px 0;
}
.modall__new_form_message {
  color: tomato;
  font-style: italic;
  padding-top: 10px;
}
.form_list__back_arrow {
  color: #9100ff;
  font-size: 28px;
  display: inline-block;
  svg {
    cursor: pointer;
    margin-right: 55px;
  }
  
}
