@import '../../../components/_globals/variables';

.guest__wrapper {
  width: 100%;
}
.register {
  width: 100%;
}
.guest__go_login {
  font-size: 13px;
  text-align: center;
  padding-bottom: 30px;
  a {
    color: $main01;
  }
}
.guest__go_login--desktop {
  a {
    color: $message-desktop;
  }
}

.guest__title {
  h1 {
    color: white;
    font-size: 22px;
    font-weight: 400;
    padding: 20px 0;
    text-align: center;
    @include small {
      color: $text-color01;
      font-size: 18px;
    }
  }
}
.guest__social {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
}
.h_social_btn {
  max-width: 250px;
  padding: 2px;
  text-align: left;

  width: 100%;
}
.guest__social_btn_linkedin {
  @extend .h_social_btn;
}
.guest__social_btn_google {
  @extend .h_social_btn;
}

.guest__form {
  width: 100%;
}
.guest__form_element {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 10px;
}
.guest__form_label {
  display: block;
  font-size: 0.8em;
  font-weight: 400;
}
.guest__form_input {
  border: 0;
  border-bottom: 1.5px solid #f2f0f0;
  font-size: 0.8em;
  padding: 5px 5px 2px 5px;
  position: relative;
  transition: $transition;
  &:focus {
    border-bottom: 2px solid $main01;
  }
}
