@import '../../../../components/_globals/variables';

.form_header__wrapper {
  margin: 10px 0;
}
.form_header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  @include medium {
    flex-direction: column;
  }
}
.form_header__title {
  font-size: 23px;
  font-weight: bold;
}
.form_header__timedata {
}
.case_header__text{
  display: inline-block;
}
.case_header__text_assigned {
  font-size: 15px;
  font-weight:normal;
}
.case_header__text_unassign {
  border-radius: 19px;
  background-color: #9100ff !important;
  font-size: 12px;
  font-weight: 400;
  margin: 0px 5px;
  padding: 6px 9px;
  line-height: normal;
  color: white;
  border-radius: 34px;
  border: none;
  cursor: pointer;
}