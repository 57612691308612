@import '../../../../components/_globals/layouts.scss';

.report__form_element {
  margin: 20px 0;
  font-family: 'Ubuntu', serif;
}

.report__form_button_wrapper {
  width: 100%;
}
.report__form_button {
  @extend .guest__button;
  display: block;
  margin: 20px auto;
  max-width: 250px;
  width: 100%;
}

.report_list__wrapper {
  display: flex;
  width: 100%;
}
.report_list {
  background: #f7f8fb;
  border-radius: 10px;
  padding: 30px;
  width: 100%;
}
.report_list__title {
  font-size: 22px;
  padding-bottom: 40px;
}
.report_list__table_wrapper {
  table {
    width: 100%;
    thead td {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }
  .pagination__num {
    background: rgba(0, 0, 0, 0);
    border: 1px solid $main01;
    margin: 4px;
  }
  .page_active {
    background: $main01;
  }
  .pagination__spread {
    position: relative;
    top: 15px;
  }
  .pagination__prev,
  .pagination__next {
    background: rgba(0, 0, 0, 0);
    border: 0;
    font-size: 20px;
    margin: 0;
    padding: 10px 5px;
  }
  .ft-table__body {
    background: rgba(0, 0, 0, 0);
    border: 0;
    td {
      font-weight: 400;
    }
  }
  .at_table__head td {
    padding-bottom: 10px;
  }
}

.report_list__exports {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.report_list__export {
  margin: 5px;
  font-size: 36px;
  cursor: pointer;
}
