.analytic-box {
    background-color: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 22px;
    box-shadow: 0 9px 11px 0 rgba(203, 203, 203, 0.5);
    width: 240px;
    height: 130px;
    margin: 16px;
}
.analytic-box-header {
    height: 21px;
    border-bottom: 1px solid #EFEFEF;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
}
.analytic-box-content {
    padding-top: 20px;
    height: 89px;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px 9px;
    }
}
.ini-end-text {
    padding-top: 20px;
}
.process-title-text {
    padding-top: 12px;
}
.process-logo {
    display: inline-block;
    padding-top: 12px;
    width: 60px;
    height: 60px;
    img {
        max-height: 60px;
        // filter: brightness(300%);
      }
}
.analytic-box-footer {
    height: 40px;
    background-color: #9100ff;
    border-bottom-right-radius: 22px;
    border-bottom-left-radius: 22px;
    padding: 9px 56px;
    display: flex;
    .ant-switch {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid white;
    }
    .ant-switch-handle{
        top: 2px;
        width: 16px;
        height: 16px;
    }
    .ant-switch-handle::before{
        border: 1px solid white;
        background-color: rgba(0, 0, 0, 0);
    }
    // justify-content: space-between;
    &__inactive {
        @extend .analytic-box-footer;
        background-color: #CACACA;
    }
    &__private {
        @extend .analytic-box-footer;
        background-color: #7D8BD4;
    }
}
.analytic-box-container {
    background-color: #F3F5F9;
    min-width: 280px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.delete-form {
    cursor: pointer;
    color: white;
    padding-right: 18px;
}
.edit-form {
    cursor: pointer;
    padding-left: 18px;
    a{
        color: white;
    }
}
.edit-intro {
    cursor: pointer;
    a{
        color: white;
    }
}