.tabs__wrapper {
  padding: 20px 0;
  max-width: 95%;
  height: calc(100vh - 100px);
}
.tabs {
  width: 100%;
}
.tabs__head {
  background: #f7f5f9;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 4px solid #f7f5f9;

  @media screen and (min-width: 768px) {
    background: white;
    justify-content: flex-start;
  }
}
.tab__title {
  border-bottom: 2px solid rgba(#6d6e71, 0);
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  position: relative;
  top: 2px;
  transition: 0.2s ease-in;
}
.tab__title--selected {
  font-weight: bold;
  color: #a579b7;
  border-bottom: 4px solid #9100ff;
}
.tabs__content {
  position: relative;
  height: calc(100vh - 209px);
  overflow: auto;
}
.tab__content {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.2s ease-in;
  @media screen and (min-width: 768px) {
    background: #f4f0fa;
  }
}

.tab__viewer {
  opacity: 1;
  padding: 30px;
  visibility: visible;
  width: 100%;
}
.tab__hider {
  opacity: 0;
  visibility: hidden;
  width: 100%;
}

.tabs__wrapper--desktop {
  .tabs__head {
    background: none;
    border-bottom: 0;
    justify-content: flex-start;
  }
  .tab__title {
    color: #929292;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 4px solid #aeaeae;
    margin: 10px;
  }
  .tab__title--selected {
    border-bottom: 4px solid #9100ff;
  }
  .home__subtitle {
    font-weight: 400;
    font-size: 20px;
  }
  .at_table__wrapper {
    background: #fcf8ff;
    border: 1px solid #f6f4f8;
    padding: 30px !important;
    font-size: 12px;
  }
}
