.hooks-store-box {
  background-color: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 22px;
  box-shadow: 0 9px 11px 0 rgba(203, 203, 203, 0.5);
  min-width: 150px;
  width: 150px;
  height: 150px;
  margin: 8px 4px;
  cursor: pointer;
}
.hooks-store-box-header {
  height: 21px;
  border-bottom: 1px solid #EFEFEF;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
}
.hooks-store-box-content {
  padding-top: 20px;
  height: 120px;
  text-align: center;
  font-size: 11px;
  line-height: 18px;
  p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px 9px;
  }
}
.ini-end-text {
  padding-top: 20px;
}
.hooks-title-text {
  padding-top: 12px;
}
.hooks-store-box-logo {
  display: inline-block;
  margin-top: 35px;
  width: 45px;
  height: 45px;
  img {
      max-height: 45px;
      // filter: brightness(300%);
    }
}
.hooks-store-box-icon{
  width: auto !important;
  svg {
    font-size: 46px;
    color: #7D8BD4;
  }
  span {
    // padding-top: 20px;
  }
}
