@import '../../../../components/_globals/variables';

.hori-coll_header {
  border-top: 3px solid #9100ff;
  border-bottom: 2px solid #e8ecf7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0 0.5rem;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}

.hori-coll_header.form-pane {
  background-color: #9100ff !important;
  color: white !important;
}

.hori-coll-container-main {
  border: 2px solid #e8ecf7;
  border-top: none;
  background: white;
  border-radius: 10px;
  width: 792px;
  @include small {
    width: 90%;
  }
  height: 100%;
  margin: 0 5px;
}

.hori-coll-container-fullscr {
  border: 2px solid #e8ecf7;
  border-top: none;
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: 0 5px;
}

.hori-coll-container {
  border: 2px solid #e8ecf7;
  border-top: none;
  background: white;
  border-radius: 10px;
  width: 528px;
  @include small {
    width: 90%;
  }
  height: 100%;
  margin: 0 5px;
}

.hori-coll_content{
  height: calc(100% - 4rem);
  overflow: auto;
  padding: 0 0.5rem;
  width: 100%;
}

.hori-coll_header_title {
  margin-left: 7px;
}

.hori-coll_header_icon {
  padding-left: 7px;
  cursor: pointer;
}

// Collapsed

.hori-coll-container_collapsed {
  border: 2px solid #e8ecf7;
  border-top: none;
  background: white;
  border-radius: 10px;
  width: 50px;
  height: 100%;
  margin: 0 5px;
}

.hori-coll-container_collapsed.form-pane {
  background-color: #9100ff !important;
  color: white !important;
}

.hori-coll_header_collapsed {
  border-top: 3px solid #9100ff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0 0.5rem;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  
}

.hori-coll_header_collapsed.form-pane {
  background-color: #9100ff !important;
  color: white !important;
}

.hori-coll_header_title_collapsed {
  margin-top: 7px;
  font-size: 18px;
  writing-mode: tb-rl;
}

.hori-coll_header_icon_collapsed{
  margin-top: 7px;
  cursor: pointer;
}

.hori-coll-container_collapsed_disabled {
  border: 2px solid #e8ecf7;
  border-top: none;
  background: white;
  border-radius: 10px;
  width: 50px;
  height: 100%;
  margin: 0 5px;
  cursor: not-allowed;
}

.hori-coll_header_icon_collapsed_disabled{
  margin-top: 7px;
}
