.home__subtitle {
  font-weight: 100 !important;
  font-size: 24px;
  padding: 16px 5px;
  color: #070707;
}

.requested {
  padding-bottom: 30px;
}

.requested--closed {
  font-size: inherit;
}

.no_data {
  background: rgba(0, 0, 0, 0.05);
  font-style: italic;
  padding: 3px 0;
}

.home__icon--table {
  color: #333333;
  cursor: pointer;
  text-align: center;
}

.home-badge .ant-badge-count {
  color: #ff4d4f;
  background-color: #ff4d4f;
}

.home__title--desktop {
  text-align: left;
  font-size: 24px;
  padding-bottom: 15px;
  font-weight: lighter;
}

.home__title--desktop__collapsed {
  color: #808080;
  font-size: 16px;
  padding-left: 45px;
}

.home__services_blocks--desktop {
  padding: 10px 10px 10px 0;
  width: 100%;
}

.home__services_blocks_featured {
  font-size: inherit;
}

.home__services_blocks_favorites {
  right: 14px;
}

.home_layout {
  padding-left: 75px;
}

.home_layout_mobile {
  padding-left: 3px;
}

.h_minor_title {
  font-size: 14px;
  font-weight: bold;
}

// COLLAPSE CUSTOM DESIGN
.home_collapse {
  width: 100%;
  background: white;
  border-radius: 4px;
  .customPanelStyle {
    overflow: 'hidden';
    border-bottom-width: 0px !important;
  }
  .customPanelStyle_collapsed {
    overflow: 'hidden';
    border-bottom-width: 0px !important;
    .ant-collapse-content-active{
      display: none;
    }
  }
  .ant-collapse-content-active {
    margin-bottom: 65px;
  }
  .ant-collapse-header{
    svg {
      font-size: 21px;
    }
  }
  .ant-collapse-header  {
    padding: 12px 0px !important;
  }
  .ant-collapse-arrow {
    right: 43px !important;
    left: auto;
  }
}

.closed_cases_collapse {
  width: 100%;
  background: white;
  border-radius: 4px;
  .customPanelStyle {
    overflow: 'hidden';
    border-bottom-width: 0px !important;
  }
  .ant-collapse-content-active {
    margin-bottom: 65px;
  }
  .ant-collapse-header{
    svg {
      font-size: 21px;
    }
  }
  .ant-collapse-header  {
    font-weight: 100 !important;
    font-size: 24px;
    padding: 16px 5px;
    color: #070707;
  }
  .ant-collapse-arrow {
    top: 8px !important;
  }
}

// TABS CUSTOM DESIGN
.tabs_home {
  width: -webkit-fill-available;
  .ant-tabs-tab {
    background-color: #f6eeff;
    color: #372B2B;
    border-radius: 32px;
    width: 315px;
    height: 64px !important;
    font-size: 18px;
    text-align: center;
    padding: 18px 16px !important;
    margin: 0 -54px 0 0 !important;
    justify-content: center;
  }
  &.tabs-mobile .ant-tabs-tab{
    width: 180px;
    height: 52px !important;
    font-size: 12px;
    margin: 0 -30px 0 0 !important;
  }
  .ant-tabs-tab-active {
    background-color: #9100ff;
    color: white !important;
    z-index: 1;
    .ant-tabs-tab-btn{
      color: white !important;
    }
  }
  .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #372B2B ;
    
  }
  .ant-tabs-bar {
    border-bottom-width: 0px;
  }
  
  .ant-tabs-ink-bar {
    display: none !important;
  }
}

.search-home-input {
  width: 400px;
  float: right; 
  border: none;
  border-bottom: 1px solid #c1bbbb;
  &:focus{
    border: none;
    border-bottom: 1px solid #8421F4;
    box-shadow: none;
  }
  &:hover{
    border: none;
    border-bottom: 1px solid #8421F4;
    box-shadow: none;
  }
}

//TABLE CUSTOM DESIGN
.tabs_cases,
.tabs_cases_mobile {
  .ant-table-thead > tr > th {
   background: #FCF9FF;
    border-bottom: 0;
    font-size: 11px;
  }
  .ant-table-tbody > tr > td {
    font-size: 15px;
  }
  .ant-table-tbody > tr > td:hover {
    font-size: inherit;
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #f6eeff !important; 
  }
  .ant-table-content {
    background: #FCF9FF;
  }
  .ant-table-wrapper {
    padding: 2px 3px;
    background: #FCF9FF;
  }
  .ant-pagination-item {
    height: 45px;
    min-width: 45px;
    line-height: 43px;
    margin-right: 10px;
    font-size: 18px;
    background-color: #FCF9FF;
    a {
      color: #808080;
    }
    &:hover {
      border-color: #8421F4;
        a {
          color: #808080;
        }
      }
      &:focus {
        a {
          color: #808080;
        }
      border-color: #8421F4;
    }
  }
  .ant-pagination-item-link{
    height: 45px !important;
    min-width: 45px;
    line-height: 40px;
    border: none !important;
    font-size: 30px !important;
    background-color: #FCF9FF !important;
    span {
      color: #808080;
    }
  }
  .ant-pagination-item-active{
    background-color: #8421F4;
    border-color: #8421F4;
    a {
      color: white;
    }
    &:hover {
      border-color:#8421F4;
        a {
          color: white;
        }
      }
      &:focus {
        a {
          color: white;
        }
      border-color: #8421F4;
    }
  }
  .ant-pagination-disabled{
    span {
      color: rgba(0, 0, 0, 0.20);
    }
  }
  .ant-pagination-item-ellipsis{
    letter-spacing: 0px;
    text-indent: 0;
  }
  .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: #8421F4;
  }
}

.home-table-loading {
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 29px 0;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 57px;
}

.tabs_cases {
  padding-right: 75px;
}

.tabs_cases_mobile {
  padding-right: 5px;
  .ant-table-wrapper {
    padding: 3px 3px !important;
  }
}