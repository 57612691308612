@import '../../../components/_globals/variables';
@import '../../GuestPages/Login/Login.scss';
@import './BeginCase/BeginCase.scss';

.flow_form__wrapper {
  width: 100%;
}

.flow_form__label {
  display: block;
  padding: 5px 0;
  font-size: 15px;
  font-weight: bold;
}

.flow_form__field {
  padding-bottom: 10px;
  input {
    font-weight: lighter;
  }
  textarea{
    font-weight: lighter;
  }
}

.flow_form__table_field {
  padding-bottom: 2px;
  input {
    font-weight: lighter;
  }
  textarea{
    font-weight: lighter;
  }
}

.flow_form__default {
  border: 0;
  border-bottom: 2px solid #dcdcdc;
  transition: $transition;
  width: 100%;
  &:focus {
    border: 1px solid $main01;
    border-bottom: 2px solid $main01;
  }
  &:disabled {
    border-radius: 5px;
    color: black;
    cursor: context-menu;
    padding: 5px;
    background: rgba(0, 0, 0, 0.1);
  }  
}

.ant-picker:hover, .ant-picker-focused{
  border-color: $main01;
}

.ant-picker-focused {
  border-color: $main01;
}

.flow_form__default-required {
  border: 0;
  border-bottom: 2px solid #ff0000;
  transition: $transition;
  width: 100%;
  &:focus {
    border-bottom: 2px solid $main01;
  }
  &:disabled {
    border-radius: 5px;
    color: black;
    cursor: context-menu;
    padding: 5px;
    background: rgba(0, 0, 0, 0.1);
  }
}

.flow_form__input_text {
  font-size: inherit;
}

.flow_form__input_textleft {
  font-size: inherit;
  text-align: left;
}

.flow_form__input_textcenter {
  font-size: inherit;
  text-align: center;
}

.flow_form__input_textright {
  font-size: inherit;
  text-align: right;
}

.flow_form__select {
  font-size: inherit;
}

.flow_form__datepicker {
  font-size: inherit;
}

.flow_form__textarea {
  min-height: 180px;
}

.flow_form__markdown {
  padding: 10px 0;
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.flow_form__field {
  .ant-calendar-picker-input {
    border: 0;
    border-bottom: 2px solid #dcdcdc;
    border-radius: 0;
    width: 100% !important;
    &:focus {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      border-bottom: 2px solid $main01;
    }
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-select {
    border: 0 !important;
    width: 100%;
  }
  .ant-select-selector {
    border: 0 !important;
    border-bottom: 2px solid #dcdcdc !important;
    &:ant-select-open {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
      border-bottom: 2px solid $main01 !important;
    }
  }  
  .ant-select-selection--single-required {
    border: 0;
    border-bottom: 2px solid #ff0000;
    &:focus {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      border-bottom: 2px solid $main01;
    }
  }
}

.form_case__actions {
  @extend .begin_case__buttons;
  display: flex;
  flex-wrap: wrap;
}

.form_case__button_wrapper {
  padding: 10px;
  width: 100%;
}

.form_case__button {
  @extend .begin_case__button;
}

.role_none {
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  position: relative;
  top: 20px;
}

.form_case__inside {
  justify-content: center;
}

.form_case__wrapper {
  background: white;
  margin: 0 auto;
}

.attachment_field {
  display: flex;
  flex-wrap: wrap;
}

.attachment_field__button {
  margin: 20px 0;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  color: white;
  background: none;
  display: flex;
  font-size: 14px;
}

.attachment_field__filename {
  display: block;
  background: #333333;
  cursor: pointer;
  padding: 10px;
  border-radius: 0 3px 3px 0;
}

.icon_bg {
  background: $main01;
  cursor: pointer;
  display: block;
  padding: 10px;
  border-radius: 3px 0 0 3px;
}

.attachment_field__status {
  background: lighten($main01, 70);
  border-radius: 10px;
  color: #333333;
  font-size: 14px;
  margin: 5px 0;
  padding: 0 10px;
  width: 100%;
}

.messaging__wrapper {
  border-left: 3px solid lighten(green, 5);
  background: white;
  padding: 0px 10px;
  width: 100%;
  @include small {
    border-left: 0;
  }
}

.messaging {
  width: 100%;
}
.messaging__textarea_wrapper {
  padding: 10px 0;
  width: 100%;
}

.messaging__textarea {
  border: 0;
  border-bottom: 2px solid #999999;
  font-size: 12px;
  margin: 10px 0;
  width: 100%;
  min-height: 62px;
}

.messaging__char_counter {
  color: #cccccc;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
}

.messaging__attachment_wrapper {
  font-size: inherit;
}

.messaging__attachment_status {
  height: 40px;
}

.messaging__attachment_title {
  color: #777777;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 0;
}

.messaging__attachment_input_wrapper {
  width: 100%;
}

.messaging__attachment_button {
  background: none;
  border: 0;
  border-bottom: 2px solid #999999;
  color: #999999;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
}

.messaging__attachment_input_icon {
  font-size: 16px;
}

.messaging__attachment_actions {
  font-size: 16px;
  a {
    color: #999999;
  }
  span {
    cursor: pointer;
  }
}

.messaging__attachment_dragg {  
  height: 80px;
  .ant-upload-drag-icon {
    margin-bottom: 6px !important;
  }
  .anticon {
    font-size: 28px !important;
  }
  .ant-upload-text {
    font-size: 14px !important;
  }
}

.messaging__button_wrapper {
  display: flex;
  justify-content: center;
}

.messaging__button {
  @extend .begin_case__button;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.7px;
  border: 0;
  min-width: 200px;
  margin: 20px 0;
  width: 50%;
}

// messages
.messagebox__wrapper {
  width: 100%;
}

.messagebox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.messagebox__element {
  border-left: 3px solid $main01;
  background: white;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px 0;
  width: 90%;
  @include small {
    border-left: 5px solid $main01;
  }
}

.messagebox__element--counterpart {
  align-self: flex-end;
  border-left: 3px solid lighten($main01, 20);
  text-align: right;
  @include small {
    border-left: 5px solid lighten($main01, 20);
  }
}

.messagebox__animated_message {
  animation-name: animated_message;
  animation-duration: 3s;
}

/* The animation code */
@keyframes animated_message {
  0% {
    background-color: #fff;
  }
  30% {
    background-color: #fae0e1;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #fae0e1;
  }
  100% {
    background-color: #fff;
  }
}

.messagebox__element_header {
  font-size: inherit;
}

.messagebox__element_username {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.messagebox__element_date {
  font-size: 12px;
  color: #999999;
}

.messagebox__element_content {
  color: #999999;
  padding: 10px 0;
  font-size: 14px;
  text-align: left ;
  p {
    white-space: pre-line;
  }
}

.messagebox__element_footer {
  font-size: inherit;
}

.messagebox__element_attachmentlist {
  font-size: inherit;
}

.messagebox__element_attachment_wrapper {
  display: flex;
  justify-content: space-between;
}

.messagebox__element_attachment_title {
  font-weight: bold;
  font-size: 12px;
}

.messagebox__element_attachment {
  a {
    color: #333333;
    font-size: 13px;
    text-decoration: underline;
    letter-spacing: 0.3px;
  }
}

.form_case__wrapper {
  color: #3f3f3f;
  padding: 10px 0;
  @include small {
    padding: 0;
  }
}

.form_case__header {
  position: relative;
}

.form_case__wrapper--case {
  background: none;
}

.form_case__wrapper--history {
  font-size: inherit;
}

.messaging__attachment_image {
  padding: 10px;
  text-align: center;
  img {
    max-width: 300px;
  }
  .messaging__attachment_link {
    color: $main01 !important;
    font-size: 20px;
  }
}

.messaging__undermessage {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background: lighten(red, 48);
  border-radius: 3px;
  font-weight: bold;
  letter-spacing: 1px;
}

.messaging__closed {
  width: 100%;
  height: 76px;
  padding: 25px;
  margin: 10px 0;
  background: #FAE0E1;
  border-radius: 3px;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  text-align: center;
  color: #842B2E;
  border: 1px solid #F8D1D5;
  border-radius: 7px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.back_down_button {
  font-size: 18px;
  display: block;
  width: 100%;
  border-radius: 30px;
  padding: 15px;
  display: flex;
  justify-content: center;
  color:  #9100ff;
  margin-bottom: 15px;
  align-items: center;
  font-weight: bold;
  &:hover {
    color: #9100ff;
  }
  svg {
    font-size: 37px;
    margin-right: 15px;
  }
  &--back {
    background: #844b9e;
  }
}

.form_case_description {
  font-size: 17px;
  color: #3f3f3f;
}

.form_case_title {
  font-size: 23px;
  font-weight: bold;
  color: #3f3f3f;
}

.flow_form__table {
  padding: 30px 0;
  h3 {
    padding-bottom: 20px;
  }
  .ant-table-wrapper {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .ant-table-cell {
    padding: 3px 1px 0px 1px !important;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 4px 4px;
}

.case_layout {
  max-width: 827px;
  margin: 0px auto !important;
  &--mobile {
    @extend .case_layout;
    margin: 0px auto !important;
  }
  &--desktop {
    @extend .case_layout;
    margin: 0px auto !important;
  }
}

.case_center {
  margin: 0px auto !important;
}

.case_header {
  font-size: inherit;
}

.form_case__back_arrow {
  color: #9100ff;
  font-size: 28px;
  display: inline-block;
  svg {
    cursor: pointer;
    margin-right: 55px;
  }  
}

.form_case__list {
  padding: 10px;
}

// CUSTOM TABS
.tabs_case{
  .ant-tabs-bar {
    @extend .case_layout;
  }
  .ant-tabs-tabpane {
    background: #fbfafd;
  }
  .ant-tabs-nav-wrap{
    max-width: 827px;
    margin: 0 auto!important;
  }
  .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-tab {
    background-color: #f6eeff;
    color: #372B2B;
    border-radius: 32px;
    width: 234px;
    height: 64px !important;
    font-size: 17px;
    text-align: center;
    padding: 18px 16px !important;
    margin: 0 -50px 0 0 !important;
    justify-content: center;
  }
  &.tabs-mobile .ant-tabs-tab{
    width: 144px;
    height: 52px !important;
    font-size: 11px;
    margin: 0 -30px 0 0 !important;
  }
  .ant-tabs-tab-active {
    background-color: #9100ff;
    color: white !important;
    z-index: 1;
    .ant-tabs-tab-btn{
      color: white !important;
    }
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #372B2B ;
  }
  .ant-tabs-bar {
    border-bottom-width: 0px;
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-nav-container {
    padding-bottom: 10px;
  }
}

// THREE DOTS ANIMATION
@keyframes blink {
  /**
    * At the start of the animation the dot
    * has an opacity of .2
    */
  0% {
    opacity: .2;
  }
  /**
    * At 20% the dot is fully visible and
    * then fades out slowly
    */
  20% {
    opacity: 1;
  }
  /**
    * Until it reaches an opacity of .2 and
    * the animation can start again
    */
  100% {
    opacity: .2;
  }
}

.dots span {
  font-size:20px;
  /**
    * Use the blink animation, which is defined above
    */
  animation-name: blink;
  /**
    * The animation should take 1.4 seconds
    */
  animation-duration: 1.4s;
  /**
    * It will repeat itself forever
    */
  animation-iteration-count: infinite;
  /**
    * This makes sure that the starting style (opacity: .2)
    * of the animation is applied before the animation starts.
    * Otherwise we would see a short flash or would have
    * to set the default styling of the dots to the same
    * as the animation. Same applies for the ending styles.
    */
  animation-fill-mode: both;
}

.dots span:nth-child(2) {
  /**
    * Starts the animation of the third dot
    * with a delay of .2s, otherwise all dots
    * would animate at the same time
    */
  animation-delay: .2s;
}

.dots span:nth-child(3) {
  /**
    * Starts the animation of the third dot
    * with a delay of .4s, otherwise all dots
    * would animate at the same time
    */
  animation-delay: .4s;
}

.Toastify__toast-container--top-center {
  width: 600px;
  text-align: center;
}

.case-markdown {
  .ck-sticky-panel__content{
    display: none;
  }
  .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border: none;
  }
}

.user-info-container {
  width: 300px;
  padding-bottom: 7px;
}

.user-info-item {
  display: flex;
}

.user-info-label {
  width: 30%;
}

.case_cont {
  background: #fbfafd;
  height: calc(100vh - 117px);
  padding: 1rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-x: auto;
  align-content: flex-start;
}

.loading-pane {
  height: 100%;
  width: 100%;
  font-size: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.aditional-pane-header {
  border-bottom: 2px solid #e8ecf7;
}

.aditional-pane-header-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .aditional-pane-header-field + .aditional-pane-header-field {
    padding-left: 10px;
  }
}

.aditional-pane-header-field {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 8px;
  span {
    min-width: 100px;
    font-size: 13px;
  }
  .ant-input {
    font-size: 13px;
  }
}

.full-pane-header-field {
  width: 100% !important;
}

.aditional-pane-header-img {
  height: 50px;
  width: 120px;
  img {
    max-height: 50px;
    max-width: 120px;
  }
}

.applicant-cases {
  padding: 1rem 0.5rem;
}

.applicant-cases-title{
  display: flex;
  justify-content: space-between;
  margin: 7px 0px;
  .label {
    font-size: 20px;
  }
  .ant-select {
    width: 190px;
  }
}

.applicant-cases-table {
  .ant-table-wrapper {
    padding: 0;
  }
  .ant-table td {
    padding: 14px 5px;
    font-size: 13px;
    text-align: center;
  }
}

.applicant-contacts {
  padding: 1rem 0.5rem;
  .applicant-contacts-title{
    display: flex;
    justify-content: space-between;
    margin: 7px 0px;
    .label {
      font-size: 20px;
    }
    .ant-select {
      width: 190px;
    }
  }
  .applicant-contacts-user-container{
    font-size: inherit;
  }
  .applicant-contacts-user-card{
    border: 1px solid darkgrey;
    border-radius: 23px;
    display: flex;
    width: 400px;
    margin-bottom: 11px;
    height: 94px;
    align-items: center;
    padding-left: 26px;
  }
  .applicant-contacts-user-card-avatar{
    display: flex;
    span {
      font-size: 23px;
    }
  }
  .applicant-contacts-user-card-upload{
    align-self: flex-end;
    cursor: pointer;
    span {
      font-size: 27px;
    }
  }
  .applicant-contacts-user-card-data{
    padding-left: 16px;
  }
  .applicant-contacts-user-card-full-name{
    color: #060606;
    font-family: Ubuntu;
    font-size: 23px;
    font-weight: 300;
    line-height: 24px;
  }
  .applicant-contacts-user-card-email{
    color: #7B7B7B;
    font-family: Ubuntu;
    font-size: 14px;
  }
  .applicant-contacts-user-card-job-title{
    color: #060606;
    font-family: Ubuntu;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.3968796502976191;
  }
  .applicant-contacts-user-card-company{
    color: #060606;
    font-family: Ubuntu;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
}

.geolocation_container {
  height: 250px;
  .leaflet-container {
    width: 100%;
    height: 250px;
  }
}
