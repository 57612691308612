@import '../../../components/_globals/layouts.scss';

.upplan__wrapper {
  width: 100%;
}
.upplan {
}
.upplan__breadcrumbs {
  display: flex;
  justify-content: center;
}
.upplan__breadcrumbs_element {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}
.upplan__breadcrumbs_num {
  align-items: center;
  border-radius: 50%;
  border: 1px solid $main02;
  color: $main02;
  display: flex;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  padding: 10px;
  transition: $transition;
  width: 50px;
}
.upplan__breadcrumbs_desc {
  color: lighten($main02, 20);
  font-size: 10px;
  padding-top: 10px;
}

.upplan__breadcrumbs_selected {
  .upplan__breadcrumbs_num {
    background: $main01;
    color: white;
  }
}

.plan_selector__wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  @include small {
    flex-direction: column;
  }
}
.plan_selector_element {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
  max-width: 230px;
  min-width: 230px;
  padding: 0;
  width: 40%;
  @include large {
    max-width: 260px;
    min-width: 200px;
  }
  @include small {
    max-width: 60%;
    min-width: 60%;
  }
}
.plan_selector__header {
  text-align: center;
  width: 100%;
}
.plan_selector__title {
  font-size: 22px;
  font-weight: bold;
  padding-top: 15px;
}
.plan_selector__subtitle {
  font-size: 20px;
}
.plan_selector__taxes {
  color: #cccccc;
}
.icon_purple {
  color: $main01;
  font-size: 18px;
  padding: 0 5px;
}
.plan_selector__features {
  padding: 20px 5px;
}
.plan_selector__feature {
  align-items: center;
  display: flex;
  font-size: 13px;
  padding: 5px;
}
.plan_selector__footer {
  width: 100%;
}
.plan_selector__button_wrapper {
  width: 100%;
}
.plan_selector__button {
  border: 0;
  border-radius: 0 0 10px 10px;
  background: $main01;
  color: white;
  cursor: pointer;
  padding: 10px;
  transition: 0.2s ease-in;
  width: 100%;
  &:hover {
    background: darken($main01, 15);
  }
  &:focus {
    background: darken($main01, 10);
  }
}

.plan_selector__moreinfo {
  text-align: center;
  a {
    color: #333333;
    text-decoration: underline;
    &:hover {
      color: $main01;
      text-decoration: none;
    }
  }
}
.plan_selector__element_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table_of_values {
  .tab__title {
    background: #777777;
    font-size: 14px;
    font-weight: bold;
    margin: 0 5px;
    color: White;
  }
  .tab__title--selected {
    background: $main01;
    color: white;
  }
}

.payment__wrapper {
  width: 100%;
}
.payment {
  display: flex;
  justify-content: space-between;
}
.payment__element {
  border: 1px solid #cccccc;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 220px;
  transition: 0.2s ease-in;
}
.payment__element_title {
  font-weight: bold;
  transition: 0.2s ease-in;
  padding: 5px 0;
}
.payment__element_desc {
}
.payment__element--selected {
  background: #f5fbff;
  border: 1px solid #84cbf8;
  .payment__element_title {
    color: #84cbf8;
  }
}

.table_update {
  background: #f7f8fb;
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.table_update__collapse {
  max-width: 768px;
  width: 100%;
  .ant-collapse-header {
    background: $main01;
    color: white !important;
    font-weight: bold;
  }
  .myacc__billing_info {
    margin: 0 auto;
    width: 100%;
    .billing_info__form {
      width: 100%;
    }
  }
}
.payment__element_left {
}
.payment__element_right {
}
.payment__radioCheck {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #cccccc;
  margin: 10px;
}
.payment__radioCheck--checked {
  background: #0098f7;
  width: 20px;
  height: 20px;
  border: 5px solid white;
  position: relative;
  &::after {
    height: 18px;
    width: 18px;
    border: 1px solid #cccccc;
    position: absolute;
    border-radius: 50%;
    top: -4px;
    left: -4px;
    content: '';
  }
}

// three payment methods styles
.elem--credit_card__checkbox {
  padding: 40px 20px;
  text-align: center;
}
.elem--credit_card__button_wrapper {
  text-align: center;
}
.elem--credit_card__button {
  @extend .guest__button;
  font-size: 14px;
  margin: 20px 0;
  padding: 10px 30px;
}
.elem--transferencia,
.elem--orden_compra {
  padding: 40px 20px;
}
.elem--transferencia__title,
.elem--orden_compra__title {
  padding-bottom: 10px;
  h2 {
    font-size: 28px;
  }
}
.elem--transferencia__text,
.elem--orden_compra__text {
  p {
    padding: 10px 0;
  }
}

.card__info {
  span img {
    width: 32px;
  }
}
.payment__errors {
  max-width: 640px;
  margin: 0 auto;
  width: 100%;
}
.payment__error {
  background: tomato;
  color: white;
  font-weight: bold;
  padding: 10px;
  margin: 10px auto;
  width: 100%;
}

.flow_form__advisor {
  font-style: italic;
}
.flow_form__message {
}
.flow_form__messagex {
  background: #eeeeee;
  border-radius: 2px;
  border: 1px solid #999999;
  padding: 10px;
  text-align: center;
}
