.metrics__wrapper {
  .tabs__head {
    border-bottom: 0;
    margin: 20px 0;
  }
  .tab__title {
  }
  .tab__title--selected {
  }
}
