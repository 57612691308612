.add-hook-box {
  background-color: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 22px;
  box-shadow: 0 9px 11px 0 rgba(203, 203, 203, 0.5);
  min-width: 100px;
  width: 100px;
  height: 120px;
  margin: 8px 4px;
}
.add-hook-box-header {
  height: 21px;
  border-bottom: 1px solid #EFEFEF;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
}
.add-hook-box-content {
  padding-top: 20px;
  height: 120px;
  text-align: center;
  font-size: 11px;
  line-height: 18px;
  p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px 9px;
  }
}
.ini-end-text {
  padding-top: 20px;
}
.hooks-title-text {
  padding-top: 12px;
}
.add-hook-box-logo {
  display: inline-block;
  padding-top: 12px;
  width: 40px;
  height: 40px;
  img {
      max-height: 40px;
      // filter: brightness(300%);
    }
}
.add-hook-box-icon{
  width: auto !important;
  svg {
    font-size: 46px;
    color: #7D8BD4;
  }
  span {
    // padding-top: 20px;
  }
}
.add-hook-box-footer {
  height: 28px;
  margin-top: 12px;
  background-color: #9100ff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  span{
    font-size: 20px;
    color: white;
  }

}
.hook-modal-body-add {
  height: 490px;
  overflow: auto;
}
