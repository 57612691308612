@import '../Register_old/Register.scss';

.guest__wrapper {
  @extend .guest__wrapper;
}
.login {
  @extend .register;
}
.guest__title {
  @extend .guest__title;
}
.guest__title--desktop {
  color: white;
  h1 {
    color: white;
  }
}
.guest__go_register {
  @extend .guest__go_login;
  margin: 0 5px;
}
.guest__social {
  @extend .guest__social;
}
.guest__form {
  @extend .guest__form;
  margin-top: 25px;
}
.guest__form_element {
  @extend .guest__form_element;
}
.guest__form_label {
  @extend .guest__form_label;
}
.guest__form_input {
  position: relative;
  padding-right: 40px;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0);
  ::placeholder {
    color: pink;
  }
  &:focus + .guest__form_ico {
    color: $main01;
  }
}
.guest__form_input_container {
  position: relative;
}
.guest__form_ico {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: 0.2s ease-in;
  z-index: 2;
}
.guest__button {
  background: $main01;
  border: 0;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  position: relative;
  padding: 5px 0;
  transition: 0.15s ease-in;
  span {
    display: inline-block;
  }
  &:hover {
    background: lighten($main01, 5);
  }
}

.button_padding {
  margin-top: 30px;
}

.guest__logo {
  padding: 10px 0;
  text-align: center;
}
.guest__logo_image {
  max-width: 90px;
  width: 100%;
}

.guest__social_btn_google {
  @extend .guest__social_btn_google;
}
.guest__social_btn_linkedin {
  @extend .guest__social_btn_linkedin;
}

.guest__forgot {
  text-align: center;
  font-size: 0.8em;
  margin-top: 20px;
  a {
    color: $main01;
  }
}

.guest__wrapper--desktop {
  display: flex;
  min-height: 100vh;
}
.guest__header--desktop {
  background: white;
  min-height: 10vh;
  display: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    max-height: 10vh;
    padding: 5px 0;
  }
  @include medium {
  }
}

.guest__side_image {
  background: url('/img/bliz_bg.jpg');
  min-height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 80vh;
  width: 50%;
  @include large {
  }
  @include medium {
  }
}
.guest__content {
  background: rgb(254, 153, 122);
  background: linear-gradient(
    0deg,
    rgba(254, 153, 122, 1) 0%,
    rgba(122, 79, 151, 1) 100%
  );
  color: white;
  padding: 50px 100px;
  width: 50%;
  @include large {
    padding: 50px;
  }
}
.guest__subtitle--desktop {
  background: none;
  h2 {
    color: white;
    font-weight: 300;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.set_right {
  text-align: right;
}
