.hook-store-modal {
  .ant-modal-body{
    height: 500px !important;
    padding: 0px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .ant-modal-content{
    border-radius: 15px;
  }
  .ant-modal-header{
    border-radius: 15px;
  }
}

.hook-store-container{
  display: flex;
  height: 100%;
}

.hook-store-list{
  height: 100%;
  width: 60%;
  border-bottom-left-radius: 15px;
  border-right: solid 1px grey;
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
}

.hook-store-description{
  height: 100%;
  width: 40%;
  border-bottom-right-radius: 15px;
}

.hook-store-description-no-info{
  display: flex;
  align-content: center;
  align-items: center;
  height: 100%;
  justify-content: center;
  font-size: 35px;
  text-align: center;
  color: grey;
}

.hook-store-description-content{
  padding: 10px;
  height: 80%;
}

.hook-store-description-company{
  font-size: 17px;
  font-weight: 500;
}

.hook-store-description-title{
  font-size: 30px;
  font-weight: 500;
}

.hook-store-description-price{
  font-size: 30px;
  font-weight: 500;
}

.hook-store-description-desc{
  font-size: 15px;
  font-weight: 200;
}

.hook-store-description-actions{
  padding: 10px;
  height: 20%;
  text-align: center;
  button{
    border: none;
    height: 50px;
    width: 150px;
    border-radius: 30px;
    background: #9100ff;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
}

.hook-store-param-title{
  font-size: 20px;
}

.hook-store-param-no-param{
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hook-store-param-field{
  font-size: 14px;
}

.hook-store-param-label{
  font-size: 16px;
}

.hook-store-param-value{
  .ant-select{
    width: 100%;
  }
}
