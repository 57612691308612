.form_timer__wrapper {
}
.form_timer {
  display: flex;
}
.form_timer__element {
  align-items: center;
  background-color: #9999c7;
  border-radius: 18.5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3px;
  padding: 4px;
  text-align: center;
  width: 100px;
}
.form_timer__element--begin {
}
.form_timer__element_sub {
  font-size: 12px;
}
.form_timer__element_time {
  font-size: 14px;
  font-weight: bold;
}
