.table-list-container {
  margin: 48px 95px 0px 95px;
  padding: 30px 60px;
  min-height: 585px;
  background-color: #F3F5F9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.table-box {
  background-color: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 22px;
  box-shadow: 0 9px 11px 0 rgba(203, 203, 203, 0.5);
  width: 290px;
  height: 226px;
  margin: 12px 15px;
}

.table-box-content {
  padding-top: 20px;
  height: 165px;
  text-align: center;
  font-size: 15px;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px 9px;
  }
}

.table-title-text {
  padding-top: 12px;
  font-size: 23px;
}

.table-icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  img {
    max-height: 60px;
  }
}

.table-box-footer {
  height: 60px;
  background-color: #9100ff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  padding: 17px 39px;
  display: flex;
  .ant-switch {
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid white;
  }
  .ant-switch-handle {
      top: 2px;
      width: 16px;
      height: 16px;
  }
  .ant-switch-handle::before {
      border: 1px solid white;
      background-color: rgba(0, 0, 0, 0);
  }
  &__inactive {
      @extend .table-box-footer;
      background-color: #CACACA;
  }
  &__deleting {
      @extend .table-box-footer;
      background-color: #CACACA;
      color: white;
      font-size: 24px;
      justify-content: center;
  }
  &__private {
      @extend .table-box-footer;
      background-color: #7D8BD4;
  }
}
