@import 'variables';
textarea:focus,
input:focus {
  outline: none;
}
.main_layout__content {
  position: relative;
  top: $xs_header_min_height;
  min-height: 100vh;
  width: 100%;
}
.main_layout__wrapper {
}
.main_layout__header {
}
.main_layout__sidebar {
}

.l_guest__wrapper {
  color: $text-color01;
  min-height: 100vh;
  width: 100%;
}
.l_guest__logo {
  background: $main01;
  color: white;
  font-weight: normal;
  font-size: 24px;
  text-transform: lowercase;
  text-align: center;
  width: 100%;
}
.atenea_fakelogo {
  margin-bottom: 10px;
  padding: 15px 0;
}
.l_guest__content {
  margin: 0;
}

.signup__google {
  border-radius: 20px;
  border: 0;
  background: #e6643f;
  color: white;
  display: inline-block;
  padding: 7px 20px;
  width: 100%;
}

.signup__linkedin {
  @extend .signup__google;
  background: #4eb9df;
}
.signup__google_text {
  padding-left: 10px;
}
.signup__linkedin_text {
  @extend .signup__google_text;
}

.guest__subtitle {
  background: #f5f5f5;
  margin: 20px 0;
  padding: 10px;
  h2 {
    color: #6e6e6e;
    font-weight: 400;
    padding-left: 10px;
    font-size: 1.1em;
  }
}
.input_alert {
  border-bottom: 2px solid #f3928f;
  &:focus {
    border-bottom: 2px solid #f3928f;
  }
}
.guest__form_error {
  color: #f3928f;
  font-size: 0.7em;
  letter-spacing: 0.3px;
}
.guest__form_error--desktop {
  color: #8ff3adeb;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.guest__form_input--error {
  &:focus {
    border-bottom: 2px solid #f3928f !important;
  }
}
.guest__message_form {
  background: white;
  color: black;
  text-align: center;
  border-top: 3px solid red;
  margin: 20px 10px 0 10px;
  padding: 10px 5px;
  @include small {
    color: $main01;
  }
  h2 {
    font-size: 18px;
  }
  p {
    font-size: 12px;
  }
}
.guest__subtitle--nobg {
  @extend .guest__subtitle;
  background: none;
  text-align: left;
}
.guest__check_icon {
  color: $main01;
  font-size: 120px;
  text-align: center;
  &--purple {
    color: $main01;
  }
  &--red {
    color: #f3928f;
    font-size: 150px;
  }
}
.desktop_layout__wrapper {
  display: flex;
  width: 100%;
}
.desktop_layout {
  max-width: 1580px;
  margin: 0 auto !important;
  float: none !important;
}
.desktop_full_layout {
  margin: 0 auto !important;
  float: none !important;
}
.desktop_layout__side {
  background: white;
  position: fixed;
  width: 90px !important;
  max-width: 90px !important;
  min-width: 90px !important;
  z-index: 200;
  
}
.desktop_layout__main {
  padding-left: 50px;
  flex: 1;
}
.desktop_layout__header {
  background: white;
  // padding-left: 18px;
}
.desktop_layout__sidebar {
}
.desktop_layout__content {
  background: white;
  padding-top: 100px;
  padding-left: 90px;
}
.desktop_layout__content--noheader {
  @extend .desktop_layout__content;
  // padding: 20px 40px;
  padding-top: 74px;
}
.fullscreen_layout {
  background: white;
}

// helpers

/* when a list no contains an image */
.no_image {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 2px;
  display: block;
  padding: 10px;
  text-align: center;
  height: 43px;
}

.back_to {
  color: $main01;
  display: block;
  padding: 20px 5px;
}

.h_center_flex {
  display: flex;
  align-items: center;
}

.h_white_block {
  background: white;
  border-radius: 10px;
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  margin: 10px;
  padding: 20px;
  p {
    text-align: justify;
  }
  .guest__title {
    h1 {
      color: #333333;
      font-weight: bold;
      letter-spacing: 0.5px;
    }
  }
}

.case_history__table_wrapper {
  background: white;
  border-top: 7px solid #7d8bd4;
  padding: 50px 30px;
  font-size: 13px;
}

.guest__button {
  background: $main01;
  border: 0;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  position: relative;
  padding: 5px 0;
  transition: 0.15s ease-in;
  span {
    display: inline-block;
  }
  &:hover {
    background: lighten($main01, 5);
  }
}

// carousel
.carousel {
  position: relative;
}

.carousel__next-button {
  position: absolute;
  top: 30%;
  color: #cccccc;
  right: 00px;
  background: none;
  border: 0;
  font-size: 32px;
  transition: 0.2s ease-in;
  &:hover {
    color: #999999;
  }
}
.carousel__back-button {
  @extend .carousel__next-button;
  left: -20px;
  right: auto;
}
.admin-message{
  width:100%;
  min-height: 30px;
  background-color: #9100ff;
  color:white;
  line-height: 29px !important;
  text-align: center;
}
.admin-message_mobile{
  margin-top: 65px;
  margin-bottom: -67px;
  width:100%;
  min-height: 30px;
  background-color: #9100ff;
  color:white;
  line-height: 29px !important;
  text-align: center;
}
