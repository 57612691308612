@import '../../../components/_globals/variables';
@import '../../../pages/GuestPages/Login/Login.scss';
.new-section-button {
    @extend .guest__button;
    padding: 10px;
    font-size: 15px;
    margin: 10px;
    width: 170px;
    height: 45px;
    border-radius: 33px;
    float: right;

}
.analytics-designer-header {
  background: #9276f4;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 26px;
    font-weight: 400;
    align-items: center;
}
.modal__new_form {
}
.modal__new_form_title {
  font-size: 13px;
  padding: 10px 0;
}
.modall__new_form_message {
  color: tomato;
  font-style: italic;
  padding-top: 10px;
}
.form_list__back_arrow {
  color: #9100ff;
  font-size: 28px;
  display: inline-block;
  svg {
    cursor: pointer;
    margin-right: 55px;
  }
  
}
.analytics-list-panel {
    background-color: #F3F5F9;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.add-analytic-icon{
    width: auto !important;
    svg {
      font-size: 72px;
      color: #7D8BD4;
    }
    span {
      padding-top: 4px;
    }
  }

.analytics-designer-properties-wrapper{
  padding: 0 25px;
}
.analytics-designer-properties-editor-container{
  flex: 1;
  overflow: auto;
  /** hard-coded height */
  height: 500px;
  background-color: whitesmoke !important;
}
.analytics-designer-code-editor-container{
  flex: 1;
  overflow: auto;
  /** hard-coded height */
  height: 720px;
  background-color: whitesmoke !important;
}
.analytics-designer-editor {
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 12px !important;
  background-color: whitesmoke !important;
  min-height: '100%' !important;
}
.no-hooks {
  text-align: center;
  font-size: 38px;
  margin-top: 78px;
  color: darkgrey;
}
.analytics-loading {
  text-align: center;
  font-size: 100px;
  margin-top: 70px;
}

.hook-modal{
  .ant-modal-body{
    height: 500px !important;
  }
}
.hooks-container{
  margin: 0px 0px 0px 9px;
  padding: 30px 21px;
  min-height: 585px;
}
