@import '../../../components/_globals/variables';
@import '../Login/Login.scss';
.register_wrapper{
  .ant-row {
    flex-flow: nowrap;
  }
  margin: 0 auto;
  background-color: white;
  // width: 1352px;
  // height: 889px;
  width: 1095px;
  height: 720px;
  img {
    flex: 1;
    height: 720px;
    width: auto;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  -webkit-box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.75);
  border-radius: 10px;


}
.register_back{
  background-color: #FAF6FD;
  height: 100vh;
  // vertical center align
  display: -ms-flexbox!important;
  display: flex!important;
  -ms-flex-align: center!important;
  align-items: center!important;
}
.register_form_wrapper {
  padding-left: 25px;
  padding-top: 12px;
  padding-right: 25px;
  
  .register_form_title {
    font-family: Ubuntu;
    font-size: 35px;
    line-height: 48px;
    text-align: center;
    // padding-bottom: 10px;
    max-width: 100%;
    color: #7B5099;
    font-weight: 600;
  }
  .register_form_subtitle {
    font-family: Ubuntu;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding-bottom: 10px;
    max-width: 100%;
    color: #94699D;
  }
  .register_form_subtitle_account {
    font-family: Ubuntu;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding-bottom: 14px;
    max-width: 100%;
    color: #5C5C5C;
    
  }
  .register_form_title_account {
    font-family: Ubuntu;
    font-size: 22px;
    line-height: 48px;
    text-align: center;
    // padding-bottom: 10px;
    max-width: 100%;
    color: #5C5C5C;
    font-weight: 600;
  }
  .register_form_inline {
    display: flex;
    justify-content: space-between;
    .register_form_field {
      width: 250px;
    }
  }
  button {
    background-image: linear-gradient(270deg, #B480FA 0%, #7D49F3 100%);
    // margin-top: 35px;
    width: 100%;
    height: 55px;
    border: 0;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: bolder;
    letter-spacing: 0.5px;
    position: relative;
    padding: 5px 0;
    transition: 0.15s ease-in;
    span {
      display: inline-block;
    }
    &:hover {
      background-image: linear-gradient(270deg, #B480FA 0%, #7D49F3 100%);
    }
  }
}
.register_modal_success {
  .ant-modal-confirm-btns{
    float: none;
    text-align: center;
  }
  button {
    @extend .guest__button;
    // padding: 10px;
    font-size: 17px;
    margin: 10px;
    width: 216px;
    height: 56px;
    border-radius: 33px;
  }
}
.register_error_message {
  height: 60px;
  background-color: rgba(240, 52, 52, 0.7);
  margin-top: -25px;
  border-radius: 20px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.register_error_message_block {
  height: 60px;
  margin-top: -25px;
}
.register_login_message {
  text-align: center;
}
