.center_widget__wrapper {
  background-color: #f6f6f6;
  border: 1px solid #ececec;
  display: flex !important;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
}

.center_widget__title {
  width: 95%;
}
.center_widget__icon {
  width: 22px;
  height: 18px;
}
.center_widget__actions {
  width: 5%;
  text-align: right;
}
.h_highlighted {
  background: rgba(yellow, 0.2) !important;
  font-weight: bold;
}
