@import '../../../components/_globals/variables';

.info_hall__wrapper {
  width: 100%;
  padding-left: 75px;
}

.info_hall__wrapper_mobile {
  width: 100%;
  padding-left: 25px;
}

.info_hall {
  width: 100%;
}

.info_hall__header {
  display: flex;
  width: 100%;
  background: white;
  padding: 0px;
  line-height: 0;
  justify-content: space-between;

  @include small {
    justify-content: center;
  }
}

.info_hall__logo {
  padding: 10px 0;
  width: 50%;

  @include small {
    text-align: center;
  }

  img {
    max-height: 55px;
  }
}

.info_hall__main {
  width: 100%;
  padding-right: 36px;
}

.info_hall__main_mobile {
  width: 100%;
}

.info_hall__image {
  width: 100%;

  @include medium {
    max-height: 150px;
  }

  @include small {
    max-height: 120px;
  }
}

.info_hall__services_wrapper {
  // margin: 20px 0;
  width: 100%;
}

.info_hall__services {
  width: 100%;
}

.info_hall__services_list {
  width: 100%;
}

.info_hall__services_element {
  align-items: center;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  list-style: none;
  min-height: 120px;
  transition: 0.2s ease-in;

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.info_hall__services_link {
  display: block;
  text-align: center;
  width: 100%;
}

.info_hall__content {
  display: flex;
  align-items: center;
  width: 100%;

  @include small {
    flex-direction: column;
  }
}

.info_hall__content_text {
  text-align: justify;
  padding: 0px 50px;
  width: 60%;

  @include small {
    width: 90%;
    padding: 30px;
  }

  p {
    padding: 20px 0;
  }
}

.info_hall__content_title {
  h1 {
    font-size: 32px;
  }
}

.info_hall__content_image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.info_hall__content_image_data {
  align-items: center;
  background: black;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 50%;
  display: flex;
  height: 300px;
  justify-content: center;
  max-height: auto;
  max-width: 100%;
  width: 300px;
  margin: 30px 0;

  @include small {
    margin-bottom: 50px;
  }
}

.info_hall__footer {
  display: flex;
  justify-content: center;

  @include small {
    flex-direction: column;
  }
}

.info_hall__footer_info {
  padding: 20px;

  @include small {
    padding: 5px;
    text-align: center;
  }
}

.info_hall__footer_contact {
  padding: 20px;

  @include small {
    padding: 5px;
    text-align: center;
  }
}

.info_hall__service_img {
  display: flex;
  justify-content: center;

  img {
    display: inline-block;
  }
}

.info_hall__service_text {
  color: #333333;
}

.info_hall__content_hook {
  @include small {
    padding: 30px;
    width: 90%;
  }
}

.services_title {
  font-size: 25px;
  font-weight: bold;
  padding: 14px 0 0px 0;

}

.button_flujo {
  // border: 1px solid #9100ff;
  background: #9100ff;
  font-size: 20px;
  font-weight: 400;
  color: white;
  margin: 0 5px;
  width: 280px;
  border-radius: 34px;
  height: 70px;
  text-align: center;

  span {
    line-height: normal;
    position: relative;
    top: 15%;
    font-weight: bold;
  }

  img {
    padding-top: 16px;
    padding-right: 10px;
  }
}