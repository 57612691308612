.loader__wrapper {
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.2s ease-in;
  width: 100%;
  z-index: 1000;
}
.loader {
  text-align: center;
  width: 50%;
  .bliz_logo {
    img {
      width: 100px;
    }
    animation-name: logo;
    animation-duration: 4s;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
}
.dot01 {
  animation-name: dots;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}
.dot02 {
  animation-name: dots;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}
.dot03 {
  animation-name: dots;
  animation-duration: 4s;
  animation-delay: 3s;
  animation-iteration-count: infinite;
}

@keyframes logo {
  0% {
    transform: scale(1) rotate(0deg);
  }
  10% {
    transform: scale(1º.3);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.3);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.3);
  }
  80% {
    transform: scale(1);
  }
}

@keyframes dots {
  0% {
    color: rgba(0, 0, 0, 1);
  }
  50% {
    color: rgba(0, 0, 0, 0);
  }
  100% {
    color: rgba(0, 0, 0, 1);
  }
}
