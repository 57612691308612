.properties {

  .header-title {
    height: 50px;
    background: #6956AB;
    color: white !important;
    font-size: 18px;
    text-align: left;
    padding: 12px 16px;
  }
}
.input-role-container {
  padding: 0 25px;
  .label {
    color: #000000;
    font-size: 14px;
    font-weight: lighter;
    text-align: left;
    // padding-bottom: 15px;
    padding-top: 15px;
  }
  .textarea {
    height: 60px !important;
  }
  .ant-input {
    height: 40px;
  }
}
.user-card {
  height: 38px;
  font-size: 12px;
  border-bottom: 1px solid #E7E6EC;
  display: flex;
  justify-content: space-between;
  .user-card-title {
    color: #201F21;
    font-weight: bold;
    
  }
  .user-card-subtitle {
    font-weight: normal;
    color: #868687;
  }
  .action-role {
    color: #868687;
    svg {
      font-size: 23px;
    }
    span {
      padding-top: 5px;
    }
  }
}
