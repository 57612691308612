@import '../_globals/variables';

.header__wrapper {
  align-items: center;
  background: #815ca1;
  display: flex;
  min-height: $xs_header_min_height;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 1;
}
.header {
  align-items: center;
  display: flex;
  width: 100%;
}
.header__menu_icon {
  color: white;
  cursor: pointer;
  flex: 1;
  font-size: 22px;
  padding: 0 10px;
}
.menu {
}
.header__searchbar {
  flex: 9;
  position: relative;
  input {
    background: white;
    border: 0;
    border-radius: 3px;
    color: $text-color01;
    font-size: 18px;
    padding: 6px 25px;
    width: 100%;
  }
}
.header__alert_icon {
  @extend .header__menu_icon;
  flex: 1;
}
.header__profile_icon {
  @extend .header__menu_icon;
  flex: 1;
}
.header__search_ico {
  position: absolute;
  display: inline-block;
  top: 7px;
  left: 7px;
}
