.ft-table-wrapper {
  width: 100%;
}
.ft-table {
  margin-top: 15px;
  width: 100%;
}
.ft-table__head {
  border-radius: 5px;
  background: #009d9d;
  color: white;
  font-weight: bold;
  tr {
    td {
    }
  }
  td {
    padding: 10px 5px;
  }
  td:nth-of-type(n + 2) {
  }
}
.ft-table__body {
  color: #5f5e5f;
  font-size: 16px;
}
.ft-table__row {
  border-bottom: 1px solid #e3e5e6;
  td {
    font-weight: 500;
    padding: 10px 4px;
  }
}
.ft-table__row--even {
}
.ft-table__row--with_children {
  cursor: pointer;
}

.ft-table__column {
  padding: 3px;
}
.ft-table__column--icon_collapse {
  display: block;
  color: #969696;
  padding-right: 5px;
  text-align: center;
  width: 40px;
}

.ft-table__pagination {
  display: flex;
  margin: 10px 0;
  justify-content: center;
  width: 100%;
}
.pagination__num {
  background: white;
  cursor: pointer;
  color: #6d6e71;
  font-weight: 500;
  padding: 10px 15px;
  user-select: none;
}
.page_active {
  background: #009d9d;
  border-radius: 2px;
  color: white;
  font-weight: 500;
  transition: 0.15s ease-in;
}
.ft-table__no_content {
  font-style: italic;
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
}
.pagination__prev,
.pagination__next {
  color: #6d6e71;
  font-weight: bold;
  font-size: 14px;
  transition: 0.2s ease-in;
  user-select: none;
  &:hover {
    color: darken(#6d6d6d, 20);
  }
}

.pagination__spread {
  color: #9d9fa2;
  font-weight: bold;
  padding: 5px;
}

.ft-table__stats {
  color: #9d9fa2;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  justify-content: space-between;
  padding-top: 10px;
  width: 100%;
}
.ft-table__left {
}
.ft-table__right {
}
.ft-table__text_color {
  color: #009d9d;
}

.h_block_cursor {
  cursor: not-allowed;
}

.home__title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}
.home__subtitle {
  font-weight: bold;
}

.at_table {
  width: 100%;
}
