@import '../../_globals/variables';

.w_ft-modal {
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  font-family: 'Ubuntu', serif;
  justify-content: center;
  height: 100%;
  left: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 100;
}
.ft-modal {
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  max-height: 90vh;
  overflow: hidden;
  padding: 5px;
  width: 40%;
  @include xsmall {
    width: 90%;
  }
  @include small {
    max-height: 95vh;
    width: 90%;
  }
  @include medium {
    max-height: 95vh;
    width: 80%;
  }
}
.ft-modal-2 {
  background: white;
  border-radius: 5px;
  max-height: 90vh;
  overflow-y: scroll;
  padding: 10px;
  width: 100%;
}
.ft-modal__title {
  padding-bottom: 10px;
  position: relative;
  h2 {
    font-weight: 300;
    text-align: center;
    width: 100%;
  }
}
.ft-modal__content {
}

.ft-modal__buttons--wrapper {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}

.ft-modal__cross {
  cursor: pointer;
  font-size: 22px;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.15s ease-in;
  &:hover {
    color: #009d9d;
  }
}
