@import '../../components/_globals/variables';

.metrics_wrapper {
  padding: 20px 0;
  width: 100%;
}
.metrics {
}
.metrics__title {
}
.chart_wrapper {
  padding-top: 50px;
}
.simple_analytic {
  background: #6d64be;
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: center;
  padding: 10px 20px;
  transition: 0.2s ease-in;
  width: 50%;
  margin: 20px;
}
.simple_analytic_image {
  display: flex;
  vertical-align: top;
  padding: 5% 1%;
  font-size: 62px;
  img {
    display: block;
    width: 60px;
    height: 60px;
    // filter: brightness(300%);
  }
}
.simple_analytic_data {
  display: flex;
  align-items: center;
}
.simple_analytic_number {
  font-size: 50px;
  font-weight: bolder;
  margin-bottom: -5%;
}
.simple_analytic_description {
  font-size: 12px;
}
.simple_analytic_title {
  font-size: 18px;
}
.simple_analytic_text{
  padding-left: 5px;
}
.simple_analytic_data_mobile {
  display: block !important;
}
