.process-statistics-container {
    .ant-statistic-title{
        font-size: 11px;
    }
    .ant-statistic-content {
        font-size: 18px;
    }
    .loading {
        font-size: 40px;
    }
    width: 300px;
}
.statistics-line {
    display: inline-flex;
    justify-content: space-between;
}
.statistics-label {
    color: rgba(0, 0, 0, 0.45);
}
.statistics-value {
    padding-right: 24px;
}
.statistics-cases {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 4px;
}