.properties {

  .header-title {
    height: 50px;
    background: #6956AB;
    color: white !important;
    font-size: 18px;
    text-align: left;
    padding: 12px 16px;
  }
}
.input-role-container {
  padding: 0 25px;
  .label {
    color: #000000;
    font-size: 14px;
    font-weight: lighter;
    text-align: left;
    // padding-bottom: 15px;
    padding-top: 15px;
  }
  .textarea {
    height: 60px !important;
  }
  .ant-input {
    height: 40px;
  }
  &__hook {
    @extend .input-role-container;
    input {
      // background-color: #ffffff;
      // border: 1px solid #ecebeb;
      // padding: 9px 12px;
      max-width: 230px;
      // margin-right: 20px;
    }
  }
}
.edit-position-button {
  padding: 10px 25px;
  button{
    min-height: 32px;
    margin-right: 5px;
    min-width: 100px;
    border-radius: 15px;
    background-color: #4A3693;
    color: white;
    border: none;
    font-size: 12px;
  }
}
.add-hook-button {
  font-size: 19px;
  padding-left: 25px;
  color: #3f3f3f;
  &:hover {
    color: #3f3f3f;
  }
}
.hook-input {
  margin-bottom: 10px;
}
.delete-hook-button {
  padding-left: 7px;
  color: #3f3f3f;
  &:hover {
    color: #3f3f3f;
  }
  svg {
    font-size: 23px;
  }
  span {
    padding-top: 5px;
  }
}
.user-card {
  height: 38px;
  font-size: 12px;
  border-bottom: 1px solid #E7E6EC;
  .user-card-title {
    color: #201F21;
    font-weight: bold;
    
  }
  .user-card-subtitle {
    font-weight: normal;
    color: #868687;
  }
}
