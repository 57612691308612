@import '../../../components/_globals/variables';


.overview_case__header {
}
.overview_case__back_arrow {
  color: #9100ff;
  font-size: 22px;
  position: absolute;
  cursor: pointer;
}
.overview_case__title,
.form_case__title {
  color: #3f3f3f;
  font-size: 10px;
  padding: 0 30px;
  text-align: center;
  width: 100%;
}
.overview_case__bridge {
  width: 100%;
}
.overview_case__bridge_title {
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 30px;
}
.overview_case__bridge_desc {
  font-size: 12px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.overview_case__form {
  width: 100%;
}
.overview_case__form_title {
  font-size: 16px;
  padding-bottom: 10px;
}
.overview_case__form_content {
  background: white;

  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
  font-size: 12px;
  @include small {
    background: #f9edff;
  }
}
.overview_case__form_data {
  color: #403d41;
  display: flex;
  padding: 5px 0;
  font-size: 15px;
  @include small {
    font-size: 12px;
  }
}
.overview_case__form_label {
  position: relative;
  padding-left: 20px;
  width: 30%;
  &:after {
    content: ':';
    position: absolute;
    right: -5px;
    top: 0;
    display: inline-block;
  }
}
.overview_case__form_value {
  font-weight: bold;
  padding-left: 15px;
  width: 70%;
  h3 {
    margin-left: -10px;
  }
  @include small {
    font-weight: 400;
  }
}
.textarea_form_value {
  white-space: pre-line;
}
// CUSTOM TABS
.tabs_overview{
  .ant-tabs-tab {
    background-color: #f6eeff;
    color: #372B2B;
    border-radius: 32px;
    width: 234px;
    height: 64px !important;
    font-size: 17px;
    text-align: center;
    padding: 18px 16px !important;
    margin: 0 -50px 0 0 !important;
  }
  &.tabs-mobile .ant-tabs-tab{
    width: 144px;
    height: 52px !important;
    font-size: 11px;
    margin: 0 -30px 0 0 !important;
  }
  .ant-tabs-tab-active {
    background-color: #9100ff;
    color: white !important;
    z-index: 1;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #372B2B ;
  }
  .ant-tabs-bar {
    border-bottom-width: 0px;
  }
  
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-tabpane-active{
    padding: 30px;
    background: #f4f0fa;
  }
}
