.mdtable-header {
  padding-left: 15px;
  padding-top: 5px;
}

.mdtable-backarrow {
  color: blue;
  padding-right: 20px;
  font-size: large;
  cursor: pointer;
}

.mdtable-content {
  width: 100%;
  min-height: calc(100vh - 36px);
}