@import '../../../components/_globals/variables';
@import '../../../pages/GuestPages/Login/Login.scss';
.new-section-button {
    @extend .guest__button;
    padding: 10px;
    font-size: 15px;
    margin: 10px;
    width: 170px;
    height: 45px;
    border-radius: 33px;
    float: right;

}
.analytics-designer-header {
  background: #9276f4;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 26px;
    font-weight: 400;
    align-items: center;
}
.modal__new_form {
}
.modal__new_form_title {
  font-size: 13px;
  padding: 10px 0;
}
.modall__new_form_message {
  color: tomato;
  font-style: italic;
  padding-top: 10px;
}
.form_list__back_arrow {
  color: #9100ff;
  font-size: 28px;
  display: inline-block;
  svg {
    cursor: pointer;
    margin-right: 55px;
  }
  
}
.analytics-list-panel {
    background-color: #F3F5F9;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.add-analytic-icon{
    width: auto !important;
    svg {
      font-size: 72px;
      color: #7D8BD4;
    }
    span {
      padding-top: 4px;
    }
  }

.analytics-designer-properties-wrapper{
  padding: 0 25px;
}
.analytics-designer-properties-editor-container{
  flex: 1;
  overflow: auto;
  /** hard-coded height */
  height: 526px;
  background-color: whitesmoke !important;
  border: 1px solid darkgray;
  border-radius: 20px;
  box-shadow: 11px 13px 13px 0px rgba(0,0,0,0.26);
  -webkit-box-shadow: 11px 13px 13px 0px rgba(0,0,0,0.26);
  -moz-box-shadow: 11px 13px 13px 0px rgba(0,0,0,0.26);
}
.analytics-designer-code-editor-container{
  flex: 1;
  overflow: auto;
  /** hard-coded height */
  height: 720px;
  background-color: whitesmoke !important;
  border: 1px solid darkgray;
  border-radius: 20px;
  box-shadow: 11px 13px 13px 0px rgba(0,0,0,0.26);
  -webkit-box-shadow: 11px 13px 13px 0px rgba(0,0,0,0.26);
  -moz-box-shadow: 11px 13px 13px 0px rgba(0,0,0,0.26);
}
.analytics-designer-editor {
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 12px !important;
  background-color: whitesmoke !important;
  min-height: '100%' !important;
}
.analytics-chart-container{
  width: 470px;
  height: 300px;
  border: 1px solid darkgray;
  box-shadow: 11px 13px 13px 0px rgba(0,0,0,0.26);
  -webkit-box-shadow: 11px 13px 13px 0px rgba(0,0,0,0.26);
  -moz-box-shadow: 11px 13px 13px 0px rgba(0,0,0,0.26);
  border-radius: 10px;
  margin: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  .zingchart-wrapper{
    // display: flex;
    // justify-content: center;
    // align-items: center;
    width: 468px !important;
    height: 298px !important;
  }
}
.analytics-chart-loading{
  font-size: 40px;
}
.simple_analytic {
  background: #6d64be;
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: center;
  padding: 10px 20px;
  transition: 0.2s ease-in;
  width: 50%;
  margin: 20px;
}
.simple_analytic_image {
  display: flex;
  vertical-align: top;
  padding: 5% 1%;
  font-size: 62px;
  img {
    display: block;
    width: 60px;
    height: 60px;
    // filter: brightness(300%);
  }
}
.simple_analytic_data {
  display: flex;
  align-items: center;
}
.simple_analytic_number {
  font-size: 50px;
  font-weight: bolder;
  margin-bottom: -5%;
}
.simple_analytic_description {
  font-size: 12px;
}
.simple_analytic_title {
  font-size: 18px;
}
.simple_analytic_text{
  padding-left: 5px;
}
.simple_analytic_data_mobile {
  display: block !important;
}