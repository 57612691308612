.form-box {
    background-color: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 22px;
    box-shadow: 0 9px 11px 0 rgba(203, 203, 203, 0.5);
    width: 142px;
    height: 110px;
    position: relative;
}
.form-box-header {
    height: 21px;
    border-bottom: 1px solid #EFEFEF;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
    display: flex;
    justify-content: flex-end;;
}
.form-box-content {
    height: 60px;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px 9px;
    }
}
.draggable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.grabbable {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.ini-end-text {
    padding-top: 20px;
}
.form-title-text {
    padding-top: 12px;
}
.form-box-footer {
    height: 29px;
    background-color: #9276F4;
    border-bottom-right-radius: 22px;
    border-bottom-left-radius: 22px;
    display: flex;
    justify-content: space-between;
    // justify-content: flex-end;
    &__intro {
        @extend .form-box-footer;
        justify-content: center;
    }
}
.form-box-container {
    background-color: #FFFFFF;
    min-width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.delete-form {
    cursor: pointer;
    color: white;
    padding-right: 18px;
}
.edit-form {
    cursor: pointer;
    padding-left: 18px;
    a{
        color: white;
    }
}
.create-action {
    cursor: pointer;
    padding-right: 18px;
}
.edit-intro {
    cursor: pointer;
    a{
        color: white;
    }
}
.action-badge {
    cursor: pointer;
    position: absolute;
    padding: 7px 7px;
    border-radius: 7px;
    background: #7B5098;
    color: white;
}