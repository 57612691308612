.form_widget__wrapper {
}
.form_widget {
  background-color: rgb(255, 255, 255);
  border: 1px solid #d0cfcf;
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  margin: 8px 14px;
  padding: 10px;
}
.form_widget--faded {
  background: lighten(#e2dcfb, 10);
}
.form_widget__icon {
  width: 22px;
  height: 18px;
}
