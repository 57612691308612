@import '../../components/_globals/variables';

.desktop_sidebar__wrapper {
  background: white;
  min-height: 100vh;
  padding-top: 3vh;
  width: 100%;
  z-index: 2;
}
.desktop_sidebar__logo {
  align-items: center;
  background: white;
  position: fixed;
  display: flex;
  top: 0;
  min-height: 8vh;
  z-index: 9;
  justify-content: center;
  width: 5vw;
  img {
    display: block;
    width: 30px;
  }
}
.desktop_sidebar__list {
  background: white;
  // border-top: 1px solid #e8ecf7;
  // border-right: 1px solid #e8ecf7;
  // border-bottom: 1px solid #e8ecf7;
  border: 1px solid #e8ecf7;
  // height: 90vh;
  margin-top: 20px;
  padding-top: 40px;
  min-height: 100vh;
  margin-top: 70px;
  // position: fixed;
}
.desktop_sidebar__element {
  // display: flex;
  width: 100%;
  justify-content: center;
  padding: 30px 22px;
  border-right: 3px solid rgba(0, 0, 0, 0);
  transition: $transition;
  &:hover {
    border-right: 3px solid rgba(0, 0, 0, 0.1);
  }
  font-size: 10px;
  display: block;
  img {
    margin-left: 6px;
  }
  span {
    display: block;
    text-align: center;
  }
}
.desktop_sidebar__link {
  img {
    width: 30px;
  }
}
.desktop_sidebar__link--non_selected {
  img {
    //filter: grayscale(100%) brightness(1.5);
  }
}
.desktop_sidebar__link--selected {
  img {
  }
}
.desktop_sidebar__element--selected {
  border-right: 3px solid #9100ff;
  color: #9100ff;
}
