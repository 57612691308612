@import '../../../../components/_globals/variables';

.featured_services {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include small {
    width: 100%;
  }
}

.featured_services__no_list {
  background: rgba(0, 0, 0, 0.05);
  padding: 3px 0;
  font-style: italic;
  width: 100%;
}
.featured_services_hall__element {
  align-items: center;
//   background: #e8deed;
  border-radius: 15px;
  color: #666369;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  height: 120px;
  justify-content: center;
  padding: 0 10px;
  transition: 0.2s ease-in;
  max-width: 150px;
  margin-left: 4vh;
//   &:focus,
//   &:hover {
//     background: #9100ff;
//     color: white;
//   }
}
.featured_services__image {
  text-align: center;
  padding: 5px 0;
  img {
    height: 48px;
    // filter: brightness(300%);
  }
}
.featured_services__description {
  font-size: 12px;
  text-align: center;
  height: 36px;
}
.featured_services__header {
  font-size: 14px;
  align-self: flex-end;
  font-weight: bolder;
}

.featured_services--desktop {
  padding: 0 !important;
  margin: 15px 0;
  width: 100%;
}

.featured_services__element--desktop {
  width: 93% !important;
  flex: none;
}
.separator_slider {
    // background: green;
    // width: 7px;
    // height: 80px;
    // position: absolute;


    // position: relative;
    // display: block;
    // box-sizing: border-box;
    // margin: 0;
    // list-style-type: none;
    // float: left;

    position: relative;
    display: block;
    box-sizing: border-box;
    height: 0;
    margin: 0px;
    list-style-type: none;
    float: left;
    width: 14.2857%;
    padding-bottom: 14.2857%;
}
.asd{
    background: green;
    width: 7px;
    height: 80px;
}