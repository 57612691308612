.my-profile-container{
    padding: 22px 40px;
    display: flex;
    flex-wrap: wrap;
}
.my-profile-info{
    width: 680px;
}
.my-profile-user-card{
    display: flex;
    max-width: 500px;
    padding-bottom: 54px;
    height: 160px;
}
.my-profile-user-card-avatar{
    display: flex;
    span {
        font-size: 34px;
    }
}
.my-profile-user-card-upload{
    align-self: flex-end;
    cursor: pointer;
    span {
        font-size: 27px;
    }
}
.my-profile-user-card-data{
    padding-left: 16px;
}
.my-profile-user-card-full-name{
    color: #060606;
    font-family: Ubuntu;
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;

}
.my-profile-user-card-email{
    color: #7B7B7B;
    font-family: Ubuntu;
    font-size: 16px;
    // line-height: 36px;
    // font-weight: 300;
}
.my-profile-user-card-job-title{
    color: #060606;
    font-family: Ubuntu;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.3968796502976191;
}
.my-profile-user-card-company{
    color: #060606;
    font-family: Ubuntu;
    font-size: 16px;
    line-height: 19px;
    text-align: left;

}
.my-profile-form{
    max-width: 540px;
    .ant-form-item-label {
        padding: 0;
    }
    label {
        color: #070707;
        font-family: Ubuntu;
        font-size: 17px;
        font-weight: 500;
    }
    input {
        border: none;
        border-bottom: 1px solid #d9d9d9;
        color: #060606;
        font-family: Ubuntu;
        font-size: 15px;
        // line-height: 18px;
    }
    input:focus {
        border-color: #9100ff;
        box-shadow: none;
        -webkit-box-shadow: 0px 1px 0px 0px #9100ff;
        box-shadow: 0px 1px 0px 0px #9100ff;
        
    }
    input:hover {
        border-color: #9100ff;
    }
    .ant-select-selector {
        border: none !important;
        border-bottom: 1px solid #d9d9d9 !important;
        color: #060606;
        font-family: Ubuntu;
        font-size: 15px;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        input:focus {
            border: none !important;
            box-shadow: none !important;
        }
    }
    .ant-select-selector:focus {
        border-color: #9100ff;
        box-shadow: none;
        -webkit-box-shadow: 0px 1px 0px 0px #9100ff;
        box-shadow: 0px 1px 0px 0px #9100ff;
    }
    .ant-select-focused {
        border-color: #9100ff;
        box-shadow: none;
        -webkit-box-shadow: 0px 1px 0px 0px #9100ff;
        box-shadow: 0px 1px 0px 0px #9100ff;

    }
}
.user-info-stats{

}

.user-info-stats-title{
    color: #060606;
    font-family: Ubuntu;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
}
.user-info-stats-subtitle{
    color: #060606;
    font-family: Ubuntu;
    font-size: 16px;
    line-height: 19px;
}
.user-info-stats-list-container {
    padding-top: 60px;
}
.user-info-stats-list-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #070707;
    font-family: Ubuntu;
    font-size: 15px;
    line-height: 18px;
    opacity: 0.4040178571428572;
    padding-bottom: 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}
.user-info-buttons {
    // background-color: black;
    text-align: center;
    width: 100%;
    padding-top: 60px;
    label {
        margin: 0 10px;
    }
    // height: 200px;
}