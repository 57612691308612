.table_widget__delete {
  background: tomato;
  border: 0;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  padding: 5px 10px;
  transition: 0.2s ease-in;
  &:hover {
    background: darken(tomato, 10);
  }
}
