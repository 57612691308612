// color variables
$main01: #9100ff;
$main02: #774290;
$text-color01: #646565;
$message-desktop: #8ff3adeb;
$error-color: rgb(162, 68, 68);

// conventions
$transition: 0.2s ease-in;

// mobile variables
// prefixes: xs = xtra small, s = small, m = medium, l = large, xl = xtra large
$xs_header_min_height: 65px;

// mobile mixins
$xsmall: 599px;
$small: 768px;
$medium: 900px;
$large: 1200px;
$xlarge: 1800px;

/* Responsive Design Mixins */
@mixin xsmall {
  @media (max-width: $xsmall) {
    @content;
  }
}
@mixin small {
  @media (max-width: $small) {
    @content;
  }
}
@mixin medium {
  @media (max-width: $medium) {
    @content;
  }
}
@mixin large {
  @media (max-width: $large) {
    @content;
  }
}
@mixin xlarge {
  @media (max-width: $xlarge) {
    @content;
  }
}

// helpers

.h_error {
  border: 2px solid $error-color;
  border-radius: 3px;
  background: lighten($error-color, 10);
  color: white;
  text-align: center;
  padding: 10px;
  width: 100%;
}

// carousel
// .carousel {
//   position: relative;
// }

// .carousel__next-button {
//   position: absolute;
//   top: 30%;
//   color: #cccccc;
//   right: 00px;
//   background: none;
//   border: 0;
//   font-size: 32px;
//   transition: 0.2s ease-in;
//   &:hover {
//     color: #999999;
//   }
// }
// .carousel__back-button {
//   @extend .carousel__next-button;
//   left: -20px;
// }
