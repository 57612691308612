@import '../NewCase/NewCase.scss';

.finish_case__wrapper {
  @extend .form_case__wrapper;
}
.finish_case__header {
}
// CUSTOM TABS
.tabs_finish_case{
  .ant-tabs-tab {
    background-color: #f6eeff;
    color: #372B2B;
    border-radius: 32px;
    width: 234px;
    height: 64px !important;
    font-size: 17px;
    text-align: center;
    padding: 18px 16px !important;
    margin: 0 -50px 0 0 !important;
  }
  &.tabs-mobile .ant-tabs-tab{
    width: 144px;
    height: 52px !important;
    font-size: 11px;
    margin: 0 -30px 0 0 !important;
  }
  .ant-tabs-tab-active {
    background-color: #9100ff;
    color: white !important;
    z-index: 1;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #372B2B ;
  }
  .ant-tabs-bar {
    border-bottom-width: 0px;
  }
  
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .form_case__wrapper{
    padding: 30px;
    background: #f4f0fa;
  }
}