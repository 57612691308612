.role-swimlane{
  height: 218px;
}
.role-options {
  background-color: #5C5C5C;
  border-bottom: 3px solid grey;
  color: white;
  font-size: 25px;
}
.option-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 218px;
  padding-left: 10px;
}
.option-action {
  cursor: pointer;
}
.role-name {
  background-color: #7B5098;
  border-bottom: 3px solid grey;
  height: 100%;
  width: 50px;
  

  //VERTICAL TEXT
  writing-mode: tb-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: white;
    font-size: 21px;
    transform: rotate(180deg);
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 17px 0px;
    
  }
}
.role-box {
  display: flex;
  border-bottom: 3px solid #6956AB;
  overflow: auto;
  // max-width: 1050px;
  max-width: calc(100vw - 450px);
}

.role-box::-webkit-scrollbar {
  display: none;
}