@import '../../../../../components/_globals/variables';
@import '../../../../../pages/GuestPages/Login/Login.scss';

.new-section-button {
  @extend .guest__button;
  padding: 10px;
  font-size: 15px;
  margin: 10px;
  width: 170px;
  height: 45px;
  border-radius: 33px;
  float: right;

}

.analytics-designer-header {
  background: #9276f4;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 26px;
  font-weight: 400;
  align-items: center;
}

.modal__new_form {}

.modal__new_form_title {
  font-size: 13px;
  padding: 10px 0;
}

.modall__new_form_message {
  color: tomato;
  font-style: italic;
  padding-top: 10px;
}

.form_list__back_arrow {
  color: #9100ff;
  font-size: 28px;
  display: inline-block;

  svg {
    cursor: pointer;
    margin-right: 55px;
  }
}

.analytics-list-panel {
  background-color: #F3F5F9;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.add-analytic-icon {
  width: auto !important;

  svg {
    font-size: 72px;
    color: #7D8BD4;
  }

  span {
    padding-top: 4px;
  }
}

.analytics-designer-properties-wrapper {
  padding: 0 25px;
}

.analytics-designer-properties-editor-container {
  flex: 1;
  overflow: auto;
  /** hard-coded height */
  height: 500px;
  background-color: whitesmoke !important;
}

.analytics-designer-code-editor-container {
  flex: 1;
  overflow: auto;
  /** hard-coded height */
  height: 720px;
  background-color: whitesmoke !important;
}

.analytics-designer-editor {
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 12px !important;
  background-color: whitesmoke !important;
  min-height: '100%' !important;
}

.no-hooks {
  text-align: center;
  font-size: 38px;
  margin-top: 78px;
  color: darkgrey;
}

.analytics-loading {
  text-align: center;
  font-size: 100px;
  margin-top: 70px;
}

.hook-modal {
  .ant-modal-body {
    height: 500px !important;
    padding: 0px 24px 10px 24px;
  }
}

.hooks-management-container {
  margin: 0px 0px 0px 9px;
  padding: 0px 47px;
  min-height: 585px;
  background-color: #F3F5F9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.hooks-box {
  background-color: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 22px;
  box-shadow: 0 9px 11px 0 rgba(203, 203, 203, 0.5);
  width: 226px;
  height: 226px;
  margin: 12px 15px;
}

.hooks-box-header {
  height: 21px;
  border-bottom: 1px solid #EFEFEF;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
}

.hooks-box-content {
  padding-top: 20px;
  height: 165px;
  text-align: center;
  font-size: 15px;
  line-height: 18px;

}

.ini-end-text {
  padding-top: 20px;
}

.hooks-title-text {
  padding-top: 12 px;
  font-size: 18px;
  text-align: left !important;
  margin: 0px 9px;
}

.hooks-title-new-text {
  padding-top: 12px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px 9px;
  }
}

.hooks-logo {
  display: inline-block;
  width: 40px;
  height: 40px;

  img {
    max-height: 40px;
    // filter: brightness(300%);
  }
}

.add-hook-icon {
  width: auto !important;

  svg {
    font-size: 72px;
    color: #7D8BD4;
  }

  span {
    padding-top: 20px;
  }
}

.hook-modal-field {
  label {
    min-width: 130px;
  }

  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .param-field {
    width: 48%;
  }

  .param-field-select {
    margin-right: 181px;
  }
}

.hook-modal-check {
  margin-top: 7px;
  margin-bottom: 13px;
}

.hooks-modal-footer {
  text-align: center;

  button {
    @extend .guest__button;
    // padding: 10px;
    font-size: 17px;
    margin: 10px;
    width: 216px;
    height: 56px;
    border-radius: 33px;
  }
}

.icon-uploader>.ant-upload {
  width: 100px;
  height: 100px;
}

.hook-modal-body {
  height: 410px;
  overflow: auto;
}

.hook-box-footer {
  height: 60px;
  background-color: #9100ff;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  padding: 17px 39px;
  display: flex;
  justify-content: center;

  .ant-switch {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
  }

  .ant-switch-handle {
    top: 2px;
    width: 16px;
    height: 16px;
  }

  .ant-switch-handle::before {
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0);
  }

  // justify-content: space-between;
  &__inactive {
    @extend .hook-box-footer;
    background-color: #CACACA;
  }

  &__deleting {
    @extend .hook-box-footer;
    background-color: #CACACA;
    color: white;
    font-size: 24px;
    justify-content: center;
  }

  &__private {
    @extend .hook-box-footer;
    background-color: #7D8BD4;
  }
}

.hook-parameter-no-params {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hook-parameter-title {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.parameter-subtitle {
  font-size: 15px;
  font-style: italic;
}

.hook-modal-param-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;

  .param-field {
    width: 47%;
  }
}

.hook-modal-param-body-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;

  .param-field {
    width: 47%;

    .ant-select {
      width: 100%;
    }
  }
}

.delete-hook-parameter {
  font-size: 20px;
  cursor: pointer;
}

.select-hook-parameter {
  width: 30px;
  text-align: center;
}

.fee-description {
  font-size: 12px;
}

.hook-parameter-body-container {
  border: solid 1px lightgrey;
  border-radius: 7px;
  padding: 8px;
  margin-bottom: 5px;
}