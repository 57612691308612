.myBox {
  background: red;
  cursor: pointer;
}
.myBox__title {
}
.myBox__category {
}
.myBox__details {
}
.myBox--faded {
  opacity: 0.5;
}

.boxer {
  background: darkcyan;
  flex: 1;
}
.boxer--isover {
}
