@import '../../GuestPages/Login/Login.scss';

.form_builder__wrapper {
  // padding: 6px 0 0 89px;
  overflow: hidden;
}

.form_builder {
  display: flex;
}

.form_builder_back_down_button {
  font-size: 18px;
  display: block;
  width: 100%;
  border-radius: 30px;
  // background: #9100ff;
  padding: 15px;
  display: flex;
  justify-content: center;
  color:  #9100ff;
  align-items: center;
  font-weight: bold;
  &:hover {
    color: #9100ff;
  }
  svg {
    font-size: 37px;
    margin-right: 15px;
  }
  &--back {
    background: #844b9e;
  }
}

.form_builder__sidebar {
  background: #F6F4FD;
  min-height: 70vh;
  flex-flow: column;
  height: calc(100vh - 50px);
  overflow: auto;
  min-width: 325px;
  // CUSTOM COLLAPSE
  .ant-collapse-header {
    height: 50px;
    background: #9013FE;
    color: white !important;
    font-size: 16px;
    text-align: center;
  }
  .ant-collapse-content-box {
    background: #F6F4FD;
  }
  .ant-collapse-item-active {
    border-bottom: 0 !important;
  }
  .ant-collapse-arrow {
    font-size: 30px !important;
  }
}

.form_builder__sub {
  align-items: center;
  background: #6956ab;
  color: white;
  height: 50px;
  padding: 0 10px;
  display: flex;
}

.float_right {
  float: right;
}

.float_left {
  float: left;
}

.form_builder__tit {
  background: #9276f4;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  p {
    font-weight: lighter;
    color: white;
    font-size: 18px;
    padding: 5px 5px;
    img {
      padding-right: 5px;
      height: 23px;
    }
  }
  b {
    font-weight: bolder;
  }
}

.form_builder__back_arrow {
  color: white;
  font-size: 32px;
  display: inline-block;
  svg {
    cursor: pointer;
    margin: 0 24px 0 15px;
  }
}

.form_builder__tit_name {
  display: inline-block;
  p {
    display: inline-block;
    vertical-align: super;
  }
  // vertical-align: super;
}

.form_builder__tit_role {
  p {
    padding-top: 10px;
    padding-right: 10px;
  }
}

.form_builer__target_subtitle {
  flex: 12;
}

.form_builer__side_properties_subtitle {
  margin-left: 15px;
  flex: 4;
}

.form_builder__content_wrapper {
  min-width: 500px;
}

.form_builder__sidebar_subtitle {
  text-transform: uppercase;
  font-weight: bold;
  margin: 7px 14px;
  font-size: 16px;
}

.form_builder__content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: calc(100vh - 160px);
  padding-bottom: 100px;
  overflow: auto;
}

.box_target {
  background: white;
  flex: 12;
  padding: 10px;
  margin: 10px;
  padding-bottom: 100px;
  height: calc(100vh - 160px);
  overflow: auto;
}

.box_target--isover {
  border: 3px dashed #979797;
}

.side_properties__wrapper {
  background: #F6F4FD;
  padding: 10px 31px;
  position: relative;
  z-index: 10;
  flex-flow: column;
  height: calc(100vh - 50px);
  overflow: auto;
  min-width: 220px;
}

.side_properties_title {
  padding: 5px 0px 27px 0;
  font-weight: bolder;
  font-size: 16px;
}

.side_properties__element {
  margin: 15px 0;
  label {
    font-size: 12px;
  }
}

.side_properties__input--element,
.side_properties__input {
  border: 0;
  font-size: 14px;
  input {
    background-color: #ffffff;
    border: 1px solid #ecebeb;
    padding: 11px 12px;
    width: 100%;
  }
  input::placeholder {
    color: #cccccc;
  }
  textarea {
    border: 1px solid #ecebeb;
    width: 100%;
    min-height: 60px;
  }
}

.side_properties__input_select--element {
  border: 0;
  font-size: 12px;
  padding-bottom: 10px;
  display: inline-flex;
  input {
    background-color: #ffffff;
    border: 1px solid #ecebeb;
    padding: 9px 12px;
    // max-width: 110px;
    margin-right: 20px;
  }  
  a {
    color: #9B9B9B;
  }
}

.side_properties__input_table {
  border: 0;
  font-size: 12px;
  padding-bottom: 10px;
  display: inline-flex;
  input {
    background-color: #ffffff;
    border: 1px solid #ecebeb;
    padding: 9px 12px;
    max-width: 120px;
    margin-right: 5px;
  }  
  a {
    color: #9B9B9B;
  }
}

.side_properties__input_table--element {
  display: inline-flex;
  min-width: 45px;
  a {
    justify-self: center;
  }
  input {
    background-color: #ffffff;
    border: 1px solid #ecebeb;
    padding: 11px 12px;
    width: auto;
  }
}

.side_properties__input_hook--element {
  border: 0;
  font-size: 12px;
  padding-bottom: 10px;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  input {
    background-color: #ffffff;
    border: 1px solid #ecebeb;
    padding: 9px 12px;
    width: 100%;
    margin-right: 5px;
  }  
  a {
    color: #9B9B9B;
  }
}

.side_properties__input--delete {
  color: #3f3f3f;
  &:hover {
    color: #3f3f3f;
  }
  svg {
    font-size: 23px;
  }
  span {
    padding-top: 5px;
  }
}

.form_builder__inside_block {
  background: rgba(#d8d8d8, 0.25);
  border: 1px solid rgba(#cbc9c9, 0.25);
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  margin: 10px;
  padding: 10px;
}

.form_builder__inside_empty {
  padding: 10px;
  text-align: center;
  color: #999999;
  font-style: italic;
  background: rgba(0, 0, 0, 0.03);
}

.form_builder__actions {
  align-items: center;
  background: #F8F8F8;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  height: 90px;
}

.side_properties__columns_wrapper {
  display: grid;
}

.form_builder__actions {
  button {
    @extend .guest__button;
    padding: 10px;
    font-size: 15px;
    margin: 10px;
    width: 180px;
  }
}

.side_properties__input--label {
  font-size: 14px;
  font-weight: lighter;
  padding-bottom: 5px;
}

.side_properties__onlytext {
  padding-bottom: 15px;
}
.side_properties__input--element,
.side_properties__input {
  font-size: 12px;
  margin-bottom: 15px;
}

.side_properties__input--select {
  width: 100%;
  .ant-select-selector {
    border: 1px solid #ecebeb !important;
    border-radius: 0 !important;
    height: 42px !important;
  }
  .ant-select-selection-item{
    margin: 5px 0;
  }
}

.side_properties__input--table_select {
  width: 128px;
  font-size: 12px;
  .ant-select-selector {
    border: 1px solid #ecebeb !important;
    border-radius: 0 !important;
    height: 42px !important;
  }
  .ant-select-selection-item{
    margin: 5px 0;
  }
}

.side_properties__input--table_checkbox {
  .ant-checkbox {
    padding-left: 0px;
  }
  span {
    font-size: 14px;
  }
}

.side_properties__add_option_btn {
  font-size: 19px;
  padding-left: 25px;
  color: #3f3f3f;
  &:hover {
    color: #3f3f3f;
  }
}

.form_fields_list{
  .ant-collapse-content-box{
    overflow: auto;
  }
}

// CUSTOM MODAL
.table_modal {
  width: 400px !important;
  height: 340px !important;
  .modal_title{
    text-align: center;
    font-size: 18px;
    font-weight: lighter;
    b {
      font-weight: normal;
    }
  }
  .ant-modal-content {
    border-radius: 10px;
    width: 400px !important;
    height: 340px !important;
  }
  .ant-modal-header {
    border-bottom: 0;
    border-radius: 10px;
  }
  .ant-modal-body {
    width: 400px !important;
    height: 180px !important;
  }
  .side_properties__columns_wrapper_modal{
    overflow: auto;
    max-height: 130px !important;
}
  .ant-modal-footer {
    border-radius: 10px;
    border-top: 0;
    text-align: center;
    button {
      width: 117px;
      border-radius: 25.5px;
      background-color:#9100ff;
      color: white;
    }
  }
}

.master_data_modal {
  width: 400px !important;
  height: 450px !important;
  .modal_title{
    text-align: center;
    font-size: 18px;
    font-weight: lighter;
    b {
      font-weight: normal;
    }
  }
  .ant-modal-content {
    border-radius: 10px;
    width: 530px !important;
    height: 560px !important;
  }
  .ant-modal-header {
    border-bottom: 0;
    border-radius: 10px;
  }
  .ant-modal-body {
    overflow: auto;
    width: 530px !important;
    height: 430px !important;
  }
  .side_properties__master_data_wrapper_modal{
    max-height: 240px !important;
    margin: 10px;
  }
  .ant-modal-footer {
    border-radius: 10px;
    border-top: 0;
    text-align: center;
    button {
      width: 117px;
      border-radius: 25.5px;
      background-color:#9100ff;
      color: white;
    }
  }
  .side_properties__input_table--element {
    input {
      max-width: 150px;
    }
    .side_properties__input--table_select {
      width: 150px;
    }
  }
}

.table_field_modal {
  width: 400px !important;
  height: 450px !important;
  .modal_title{
    text-align: center;
    font-size: 18px;
    font-weight: lighter;
    b {
      font-weight: normal;
    }
  }
  .ant-modal-content {
    border-radius: 10px;
    width: 530px !important;
    height: 560px !important;
  }
  .ant-modal-header {
    border-bottom: 0;
    border-radius: 10px;
  }
  .ant-modal-body {
    overflow: auto;
    width: 530px !important;
    height: 430px !important;
  }
  .side_properties__master_data_wrapper_modal{
    // overflow: auto;
    max-height: 240px !important;
    margin: 10px;
  }
  .ant-modal-footer {
    border-radius: 10px;
    border-top: 0;
    text-align: center;
    button {
      width: 117px;
      border-radius: 25.5px;
      background-color:#9100ff;
      color: white;
    }
  }
  .side_properties__input_table--element {
    display: flex;
    input {
      max-width: 150px;
    }
    .side_properties__input--table_select {
      width: 150px;
    }
  }
}

.resize-handler {
  z-index: 100;
  img {
    height: 20px;
  }
}

.add-hook-container {
  display: flex;
  overflow-x: auto;
}

.droppabble {
  height: 200px;
  width: 200px;
  background-color: blue;
}
